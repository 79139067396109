import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';
import {IParticipantData} from "../../lib/types";
import {toast} from "react-toastify";

export const editParticipantProfileByAdmin = createAsyncThunk(
  'admin/editParticipantProfile', async (participantData: IParticipantData, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.editParticipantProfileByAdmin(participantData);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 400 && error.response.data.title === 'User does not exist') {
        toast.error('Пользователя не существует', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Error');
      }

      if (error.response.data.status === 400 && error.response.data.title === 'One or more validation errors occurred.') {
        toast.error('Убедитесь, что заполнили все поля', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Error');
      }

      toast.error('Ой, что то пошло не так, попробуйте обновить странциу', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue('Error');
    }
  }
);
