import React from 'react';
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

type SwipeableDrawerProps = {
  container: (() => HTMLElement) | undefined;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawer: React.ReactNode;
}

const SwipeDrawer: React.FC<SwipeableDrawerProps> = (props): JSX.Element => {
  const {container, mobileOpen, handleDrawerToggle, drawer} = props;
  return (
    <Box component="nav">
      <SwipeableDrawer
        onOpen={() => {
        }}
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: '75%'},
        }}
      >
        {drawer}
      </SwipeableDrawer>
    </Box>
  );
};

export default SwipeDrawer;
