import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const getUsers = createAsyncThunk(
  "admin/getUsers",
  async (query: any, { rejectWithValue, signal }) => {
    try {
      const { data } = await adminAPI.getUsers(query, signal);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Error");
    }
  }
);
