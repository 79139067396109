import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/lib/hooks/hooks";
import CustomNavigate from "shared/lib/CustomNavigate/CustomNavigate";
import Header from "widget/Header/Header";
import AdminHeader from "widget/AdminHeader/AdminHeader";
import { USER_LOGIN_PATH } from "pages/paths";

const Layout: React.FC = (): JSX.Element => {
  const userRole = useAppSelector((state) => state.users.userRole);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !localStorage.getItem("AccessToken") &&
      location.pathname.includes("/personal_cabinet")
    ) {
      navigate(USER_LOGIN_PATH, {
        state: { from: location.pathname },
      });
    }
  }, []);

  return (
    <>
      <CustomNavigate />
      {userRole === "Admin" ||
      userRole === "Owner" ||
      userRole === "Volunteer" ? (
        <AdminHeader />
      ) : (
        <Header />
      )}
      <Outlet />
    </>
  );
};

export default Layout;
