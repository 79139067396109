import { ICreateMarathon } from "features/createMarathon/types";
import { useFieldArray, useFormContext } from "react-hook-form";

export const useMarathonTabForm = () => {
  const {
    watch,
    register,
    control,
    setValue,
    resetField,
    clearErrors,
    formState: {
      errors
    }
  } = useFormContext<ICreateMarathon>();
  const {
    fields,
    append: appendDocument,
    remove: removeDocument,
  } = useFieldArray({
    control,
    name: "documents",
  });

  const {
    append: appendPublicOffer,
    remove: removePublicOffer,
    insert: insertPublicOffer,
    replace: replacePublicOffer,
    update: updatePublickOffer
  } = useFieldArray({ name: "publicOffer", control });
  const {
    fields: fieldsPartners,
    append: appendPartners,
    remove: removePartners,
  } = useFieldArray({ control, name: "partners" });

  const data = watch();
  return {
    clearErrors,
    updatePublickOffer,
    replacePublicOffer,
    errors,
    insertPublicOffer,
    fieldsPartners,
    appendPartners,
    removePartners,
    data,
    register,
    fields,
    appendDocument,
    control,
    setValue,
    removeDocument,
    resetField,
    appendPublicOffer,
    removePublicOffer,
  };
};
