import { Box, Button } from "@mui/material";
import { useCreateDistance } from "features/createMarathon/model/Distance/useCreateDistance";
import { CreateDistanceItem } from "./CreateDistanceItem";

export const CreateDistanceForm = () => {
  const { appendDistance, fieldsDistance, removeDistance } =
    useCreateDistance();

  return (
    <Box>
      {fieldsDistance.map((distance, index) => {
        return (
          <CreateDistanceItem
            key={distance.id}
            index={index}
            removeDistance={removeDistance}
          />
        );
      })}
      <Button
        sx={{
          ...createCategoryBtnStyles,
          marginTop: "25px",
          marginBottom: "22px",
        }}
        onClick={() => {
          appendDistance(
            // @ts-ignore
            {
              distanceAges: [
                { ageFrom: "", ageTo: "", gender: false },
                { ageFrom: "", ageTo: "", gender: true },
              ],
            }
          );
        }}
      >
        Добавить дистанцию
      </Button>
    </Box>
  );
};

export const createCategoryBtnStyles = {
  color: "#0090D1",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
};
