import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const getVerifiedUsers = createAsyncThunk(
  "admin/getVerifiedUsers",
  async (query: any, { rejectWithValue, signal }) => {
    try {
      const { data } = await adminAPI.getVerifiedUsers(query, signal);
      return data;
    } catch (error: any) {
      console.log(error);

      return rejectWithValue("Error");
    }
  }
);
