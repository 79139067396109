import { Box, TextField } from "@mui/material";
import { Calendar, CalendarIcon } from "assets/icons";
import { Control, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import clsx from "clsx";
import styles from "./ui-date-field.module.scss";

export type UiSelectOption = {
  value: string;
  label: string;
};

export type UiSelectFieldProps = {
  className?: string;
  label?: string;
  error?: any;
  options?: UiSelectOption[];
  control?: Control<any, any>;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: Date | Dayjs | undefined;
  minDate?: Date | Dayjs | undefined;
};

export function UiDatetField({
  className,
  error,
  label,
  control,
  name,
  placeholder,
  disabled,
  maxDate,
  minDate,
}: UiSelectFieldProps) {
  const id = label! + Math.random();

  return (
    <Box className={clsx(className, styles.dateWrapper)}>
      {label && (
        <label style={{ height: "17px" }} htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value = null },
          fieldState: { error },
        }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                components={{
                  OpenPickerIcon: disabled ? Calendar : CalendarIcon,
                }}
                inputFormat="DD.MM.YYYY"
                value={value}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                    style: { justifyContent: "flex-start" },
                  },
                }}
                onChange={(event) => {
                  onChange(event);
                }}
                renderInput={({ ...params }) => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder,
                        autoComplete: "off",
                      }}
                      size="small"
                      focused={!!error}
                      error={!!error}
                      helperText={error?.message}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "13px",
                          border: "1px solid #C0D4E3",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "40px",
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </Box>
  );
}
