import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {typedAdminAPI} from '../api/typedApi';

export const deleteMarathon = createAsyncThunk(
  'deleteMarathonById/admin', async (marathonId: number, {rejectWithValue}) => {
      try {
        const response = await typedAdminAPI.deleteMarathon(marathonId);
        return response.data;
      } catch (error: any) {

        if (error.response.data.status === 400) {
          toast.error(error.response.data.title, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return rejectWithValue(error.response.data.title);
        } else if (error.response.data.status > 400){
          toast.error('Ой! Что-то пошло не так', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return rejectWithValue('Ой! Что-то пошло не так');
        }
      }
  }
)
