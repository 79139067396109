import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const putUserStatus = createAsyncThunk(
  "admin/putUserStatus",
  async (data : any, {rejectWithValue}) => {
    try {
      const res = await adminAPI.putUserStatus(data);
      return res.data;
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
