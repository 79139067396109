import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector, useDebounce} from "shared/lib/hooks/hooks"
import {useTranslation} from "react-i18next";
import {getActiveMarathonById, getActiveMarathons} from "entities/user/model/thunks";
import {countriesArrWithTranslations} from "entities/viewer/lib/profileData";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import disabledPeopleIcon from "assets/images/admin/userDetail/disabled_people.svg";
import search from "assets/images/admin/search.svg";
import {participantListTableCell} from "entities/admin/lib/MUIStyles/MUIStyles";
import CircularProgress from "@mui/material/CircularProgress";
import styles from './ActiveMarathonParticipants.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: "16px 8px"}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ECF2F7',
    color: '#3E444A',
    padding: '8px',
    fontFamily: 'Raleway',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '700 !important',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5FAFF',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ActiveMarathonParticipants = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem('i18nextLng');

  const activeMarathons = useAppSelector(state => state.users.activeMarathons);
  const activeMarathonById = useAppSelector(state => state.users.activeMarathonById);
  const isGetActiveMarathonByIdLoading = useAppSelector(state => state.users.isGetActiveMarathonByIdLoading);
  const activeMarathonParticipantsCount = useAppSelector(state => state.users.activeMarathonParticipantsCount);

  const [participantsSearchInfo, setParticipantsSearchInfo] = useState('');
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const debouncedValue = useDebounce<string>(participantsSearchInfo, 800);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
    setParticipantsSearchInfo('');
  };

  const handleOnPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  useEffect(() => {
    dispatch(getActiveMarathons());
  }, [dispatch, currentLanguage]);

  useEffect(() => {
    if (activeMarathons.length) {
      dispatch(getActiveMarathonById({marathonId: activeMarathons[value].id, page, pageSize, participantsSearchInfo: debouncedValue}));
    }
  }, [dispatch, value, activeMarathons, page, pageSize, debouncedValue]);

  return (
    <div className={styles.container}>
      <h1 className={styles.participantsMainTitle}>
        {t('activeMarathonListOfParticipants.mainTitle')} {activeMarathonParticipantsCount}
      </h1>
      <div className={styles.listOfParticipants}>
        <Box sx={{width: '100%'}} style={{fontFamily: "Raleway sans-serif"}}>
          <Box sx={{borderBottom: 1, borderColor: 'divider', overFlow: 'auto'}}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="basic tabs example"
            >
              {activeMarathons.map(activeMarathon => (
                <Tab
                  key={activeMarathon.id}
                  wrapped
                  label={activeMarathon.name}
                  {...a11yProps(0)}
                  sx={{textTransform: "none", fontSize: "14px"}}
                />
              ))}
            </Tabs>
          </Box>
          <TabPanel value={value} index={value}>
            <div className={styles.participantsSearchInputContainer}>
              <input
                type="text"
                value={participantsSearchInfo}
                onChange={e => {
                  setParticipantsSearchInfo(e.target.value);
                  setPage(1);
                }}
                placeholder={t('activeMarathonListOfParticipants.searchParticipantInput')}
                className={styles.participantsSearchInput}
              />
              <img
                src={search}
                alt="Поиск"
                className={styles.participantsSearchIcon}
              />
            </div>

            {isGetActiveMarathonByIdLoading ? (
              <Box sx={{display: 'flex', justifyContent: 'center', margin: '25px'}}>
                <CircularProgress/>
              </Box>
            ) : activeMarathonById.length === 0 ? (
              <Box>
                <p className={styles.noRecords}>{t('activeMarathonListOfParticipants.noRecords')}</p>
              </Box>
            ) : (
              <>
                <TableContainer
                  component={Paper}
                  sx={{maxHeight: '100vh', boxShadow: 'none', marginTop: '30px', borderRadius: '12px 12px 0 0'}}>
                  <Table
                    stickyHeader
                    aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className={styles.startNumberColumn} sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.startNumber')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.name')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.surname')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.gender')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.category')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.age')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.distance')}
                        </StyledTableCell>
                        <StyledTableCell sx={{border: 'none'}} align="center">
                          {t('activeMarathonListOfParticipants.country')}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeMarathonById.map(activeMarathonByIdUser => (
                        <StyledTableRow key={activeMarathonByIdUser.id}>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}>
                            <div className={styles.startNumberContainer}>
                              {activeMarathonByIdUser.isPWD ? (
                                <img
                                  style={{width: '25px', height: '25px'}}
                                  src={disabledPeopleIcon}
                                  alt="Люди с ограниченными возможностями"
                                />
                              ) : null}
                              <span style={{margin : activeMarathonByIdUser.isPWD ? '0' : 'auto'}}>
                              {activeMarathonByIdUser.number}
                            </span>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}>
                            {activeMarathonByIdUser.user.name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}
                          >
                            {activeMarathonByIdUser.user.surname}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}
                          >
                            {currentLanguage === 'ru' ? activeMarathonByIdUser.user.gender ? 'М' : 'Ж' : null}
                            {currentLanguage === 'kg' ? activeMarathonByIdUser.user.gender ? 'Э' : 'А' : null}
                            {currentLanguage === 'en' ? activeMarathonByIdUser.user.gender ? 'M' : 'F' : null}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}
                          >
                            {activeMarathonByIdUser.distanceAge || t('activeMarathonListOfParticipants.pwd')}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}
                          >
                            {activeMarathonByIdUser.user.age}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, minWidth: "200px", wordBreak: 'break-word', color: '#3e444a'}}
                          >
                            {activeMarathonByIdUser.distance}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{...participantListTableCell, color: '#3e444a'}}>
                            {currentLanguage !== null && (
                              countriesArrWithTranslations[currentLanguage as keyof typeof countriesArrWithTranslations][activeMarathonByIdUser?.user?.country]
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} sx={{margin: '30px auto 20px auto', width: '350px', alignItems: 'center'}}>
                  <Pagination
                    count={activeMarathonParticipantsCount ? Math.ceil(activeMarathonParticipantsCount / pageSize) : 5}
                    page={page}
                    onChange={handleOnPageChange}
                    color="primary"
                    size="medium"
                    variant="outlined"
                    shape="rounded"
                    sx={{margin: '0 auto'}}
                  />
                </Stack>
              </>
            )}
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default ActiveMarathonParticipants;
