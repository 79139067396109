import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getMyApplicationsCreateThunk = createAsyncThunk(
  "personalCabinet/getMyApplications",
  async () => {
    try {
      const { data } = await personalCabinetAPI.getMyApplications();
      return data;
    } catch (error) {}
  }
);
