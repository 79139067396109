import { Box, Button } from "@mui/material";
import { buttonFileStyle } from "shared/lib/style";
import style from "./load-file.module.scss";
import fileIcon from "assets/images/myDocument/file.svg";
import btnIcon from "assets/images/myDocument/btn_icon.svg";
import fileImg from "assets/images/admin/file.svg";
import trashRedImg from "assets/images/admin/trash_red.svg";
import { ICreateMarathon } from "features/createMarathon/types";
import {
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  useFormContext,
} from "react-hook-form";
import { useState } from "react";

interface ILoadDocumentProps {
  data: ICreateMarathon;
  removePublicOffer: (index: number) => void;
  appendPublicOffer: UseFieldArrayAppend<ICreateMarathon, "publicOffer">;
  languageId: number;
  indexLanguage: number;
  insertPublicOffer: UseFieldArrayInsert<ICreateMarathon, "publicOffer">;
  replacePublicOffer: UseFieldArrayReplace<ICreateMarathon, "publicOffer">;
  updatePublickOffer: UseFieldArrayUpdate<ICreateMarathon, "publicOffer">;
}

export const LoadPublicoffer: React.FC<ILoadDocumentProps> = ({
  data,
  removePublicOffer,
  appendPublicOffer,
  languageId,
  indexLanguage,
  insertPublicOffer,
  replacePublicOffer,
  updatePublickOffer,
}) => {
  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const findData = data.publicOffer.find(
    (item) => item.languageId === languageId
  );
  const findedIndex = data.publicOffer.findIndex(
    (item) => item.languageId === languageId
  );
  const { register, setValue, resetField } = useFormContext<ICreateMarathon>();

  const PublickError = errors?.publicOffer?.[findedIndex]?.file;
  const [isDragOpen, setIsDragOpen] = useState(false);
  return (
    <Box>
      <label className={`label-personalCabinet ${style.label}`} htmlFor="-">
        Правила участия
        {!findData?.file?.[0] && (
          <div
            style={{ border: PublickError?.type ? "2px dashed red" : "" }}
            className={`${style.box_load} ${
              isDragOpen ? style.box_load_drag : ""
            }  `}
            onDragStart={(e) => {
              e.preventDefault();
              console.log("start");
              setIsDragOpen(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              console.log("leave");
              setIsDragOpen(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              console.log("over");
              setIsDragOpen(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files;
              console.log({
                languageId: languageId,
                isUpdated: false,
                // @ts-ignore
                file,
              });
              // @ts-ignore
              setValue(`publicOffer.${findedIndex}.file`, file);
              console.log("drop");
              setIsDragOpen(false);
            }}
          >
            <img src={fileIcon} alt="Файл" />
            <div className={style.box_load_text}>
              {/* <p className={"errorTextValidation"}>{arrTextError[index]}</p> */}
              {isDragOpen ? (
                <p>Отпустите файл, чтобы загрузить его</p>
              ) : (
                <>
                  <p>
                    Перетащите файл в область <br /> для загрузки с компьютера,
                    либо
                  </p>
                  <Button
                    variant="contained"
                    component="label"
                    sx={buttonFileStyle}
                    className={style.box_load_btn}
                  >
                    <img src={btnIcon} alt="Выбрать файл" />
                    <span>Выбрать файл</span>
                    <input
                      type="file"
                      {...register(`publicOffer.${findedIndex}.file`)}
                      accept=".pdf,.docx"
                      hidden
                    />
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
        {findData?.file?.[0] ? (
          <div className={style.main}>
            <div className={style.main__first}>
              <img className={style.main__image} src={fileImg} alt="Файл" />
              <p className={style.main__fileName}>
                {findData?.file?.[0]?.name}{" "}
                <span>
                  {findData?.file?.[0].size < 1048576
                    ? `${(findData?.file?.[0].size / 1024).toFixed(1)} KB`
                    : `${(findData?.file?.[0].size / 1048576).toFixed(1)} MB`}
                </span>
              </p>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                src={trashRedImg}
                alt="Удалить файл"
                onClick={() => resetField(`publicOffer.${findedIndex}.file`)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </label>
      <Box>
        <Box className="globalErrorMessage">{PublickError?.message}</Box>
      </Box>
    </Box>
  );
};
