import {createAsyncThunk} from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getMyResults = createAsyncThunk('personalCabinet/getMyResults',
  async (_, {rejectedWithValue}) => {
    try {
      const response = await personalCabinetAPI.getMyResults();
      return response.data;
    } catch (error) {
      return rejectedWithValue('Error');
    }
  });
