import React, {useEffect} from 'react';
import i18n from "i18next";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import russianFlag from 'assets/images/header/russianFlag.svg';
import britishFlag from 'assets/images/header/britishFlag.svg';
import kyrgyzFlag from 'assets/images/header/kyrgyzFlag.svg';
import {HeaderArrowDown} from 'assets/icons';
import styles from './LanguageDropDown.module.scss';

const languageDropDownMenu = [
  {language: 'РУС', image: russianFlag, value: 'ru'},
  {language: 'EN', image: britishFlag, value: 'en'},
  {language: 'КЫРГ', image: kyrgyzFlag, value: 'kg'},
];

type Props = {
  isSelectOpen?: any,
}

const LanguageDropDown: React.FC<Props> = ({isSelectOpen}): JSX.Element => {
  const [language, setLanguage] = React.useState(i18n.language.slice(0, 2));

  const handleChange = (e: SelectChangeEvent) => {
    setLanguage(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value)
    // @ts-ignore
    i18n.changeLanguage(localStorage.getItem("i18nextLng"))
  };

  useEffect(() => {
    const lng = localStorage.getItem("i18nextLng");
    lng === 'ru' && setLanguage('ru');
    lng === 'en' && setLanguage('en');
    lng === 'kg' && setLanguage('kg');
  }, [language])

  return (
    <Box sx={{maxWidth: 120, marginLeft: 'auto', height: '35px', padding: '0'}}>
      <FormControl fullWidth sx={{
        "& .Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 'none',
            outlineColor: 'red',
            outlineWidth: '1px',
            padding: '0 15px',
          },
      }}>
        <Select
          open={isSelectOpen}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '20px',
                padding: '0 10px',
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "#F0F8FF",
                  borderRadius: '8px',
                },
              },
            }
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {border: 0},
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#3E444A',
            '.MuiSelect-select': {
              padding: '4px 0 0 10px'
            }
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
          IconComponent={(props) => <HeaderArrowDown {...props} style={{
            right: '1px',
            top: 'calc(50% - 0.9em)'
          }}/>}
        >
          {languageDropDownMenu.map((menuItem, index) => (
            menuItem.value === language ? (
              <MenuItem
                value={menuItem.value}
                key={index}
                style={{display: 'none'}}
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '14px',
                  color: '#3E444A',
                  lineHeight: '24px',
                }}>
                <img
                  src={menuItem.image}
                  className={styles.visibleFlag}
                  alt={`${menuItem.value + 'flag'}`}
                />
                <span className={styles.visibleLanguage}>{menuItem.language}</span>
              </MenuItem>
            ) : (
              <MenuItem
                value={menuItem.value}
                key={index}
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '14px',
                  color: '#3E444A',
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                <img
                  src={menuItem.image}
                  className={styles.dropDownFlag}
                  alt={`${menuItem.value + 'flag'}`}
                />
                <span className={styles.dropDownLanguage}>{menuItem.language}</span>
              </MenuItem>
            )
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageDropDown;
