import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import { toast } from "react-toastify";

export const createVoucher = createAsyncThunk(
  "admin/createVoucher",
  async (voucherData: any, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.createVoucher(voucherData);
      return response.data;
    } catch (error: any) {
      if (error.response.status >= 400 && error.response.data.status < 500) {
        toast.error(error.response.data.title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        rejectWithValue("Error");
      }

      if (error.response.status >= 500 && error.response.status <= 526) {
        toast.error("Ой, что то пошло не так, попробуйте обновить страницу", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        rejectWithValue("Error");
      }

      return rejectWithValue("Error");
    }
  }
);
