import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const getMarathonsCreateThunk = createAsyncThunk(
    "personalCabinet/marathons",
    async (payload, { rejectedWithValue }) => {
        try {
            const { data } = await personalCabinetAPI.getMarathons(payload)
            return data;
          } catch (error) {
            return rejectedWithValue(error.response.data);
          }
    }
)
