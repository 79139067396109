import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const putFileCreateThunk = createAsyncThunk(
    "personalCabinet/uploadFile",
    async (fileData, { rejectedWithValue }) => {
        try{
            const { data } = await personalCabinetAPI.putFile(fileData)
            return data;
        }catch(error){
            return rejectedWithValue(error.response.data);
        }
    }
)