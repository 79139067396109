import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "../api/index";

export const postApplicationMoneyCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationMoney",
  async (id, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.postApplicationMoney(id)
      window.location.href = res.data
      return res.data;
    } catch (error) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title)
    }
  }
);
