import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import ru from "./locales/ru.json";
import en from "./locales/en.json";
import kg from "./locales/kg.json";

const options = {
  resources: {
    ru: {
      common: ru,
    },
    en: {
      common: en,
    },
    kg: {
      common: kg,
    },
  },
  ns: ["common"],
  lng:
    localStorage.getItem("i18nextLng") === null
      ? "ru"
      : localStorage.getItem("i18nextLng"),
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
