import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const UserChangePasswordSkeleton: React.FC = (): JSX.Element => {
  return (
    <Grid item sx={{
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      }
    }}>
      <Skeleton animation="pulse" variant="rectangular" sx={{
        width: '50%',
        minHeight: '91.9vh',
        '@media screen and (max-width: 767px)': {
          flexDirection: 'column',
          minHeight: '35vh',
          width: '100%'
        }
      }}/>
      <Stack sx={{
        width: '30%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 1130px)': {
          width: '40%',
        },
        '@media screen and (max-width: 767px)': {
          height: '50vh',
          width: '60%',
        },
        '@media screen and (max-width: 560px)': {
          width: '80%',
        }
      }}>
        <Skeleton
          animation="pulse"
          variant="text"
          height={50}
          sx={{
            width: '60%',
            fontSize: '28px',
            marginBottom: '10px',
          }}
        />
        <Grid item sx={{width: '90%'}}>
          <Skeleton
            animation="pulse"
            variant="text"
            width={40}
            sx={{
              fontSize: '14px',
              marginBottom: '-5px',
              marginRight: 'auto',
            }}/>
        </Grid>
        <Skeleton
          animation="pulse"
          height={50}
          sx={{
            width: '90%',
            borderRadius: '10px',
            marginBottom: '13px',
          }}/>
        <Grid item sx={{width: '90%',}}>
          <Skeleton
            animation="pulse"
            variant="text"
            width={120}
            sx={{
              fontSize: '14px',
              marginBottom: '-5px',
              marginRight: 'auto',
            }}/>
        </Grid>
        <Skeleton
          animation="pulse"
          height={50}
          sx={{
            width: '90%',
            borderRadius: '10px',
            marginBottom: '7px',
          }}/>
        <Skeleton
          animation="pulse"
          height={50}
          sx={{
            width: '90%',
            borderRadius: '10px',
          }}/>
      </Stack>
    </Grid>
  );
};

export default UserChangePasswordSkeleton;
