import {createAsyncThunk} from "@reduxjs/toolkit";
import {personalCabinetAPI} from "../api";

export const getVoucherDistancesCreateThunk = createAsyncThunk(
  'personalCabinet/getVoucherDistancesCreateThunk',
  async ({id,corporateClientId},{rejectWithValue}) => {
    try {
      const {data} = await personalCabinetAPI.getVoucherDistances({id,corporateClientId})
      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
