import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const getParticipantInfoBySearchName = createAsyncThunk(
  'admin/getParticipantInfoBySearchName', async (participantInfo: any, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.getParticipantInfoBySearchName(participantInfo);
      return response.data;
    } catch (error) {
      //Don't need to handle error
      return rejectWithValue('Error');
    }
  }
);
