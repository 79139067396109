import { Box, Tabs } from "@mui/material";

import styles from "./marathon.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";

export const MarathonNav = () => {
  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  return (
    <Box className={styles.nav}>
      <NavLink
        className={({ isActive }) =>
          isActive ? styles.nav_activeLink : undefined
        }
        to={"/new/step-1"}
      >
        <div className={styles.nav_line}></div>
        <h5 className={styles.nav_title}>Шаг 1. Контент</h5>
        <p className={styles.nav_text}>
          Заполните основную информацию на трех языках и загрузите документы
        </p>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive ? styles.nav_activeLink : undefined
        }
        to={"/new/step-2"}
      >
        <div className={styles.nav_line}></div>
        <h5 className={styles.nav_title}>Шаг 2. Настройки</h5>
        <p className={styles.nav_text}>
          Установите даты приема заявок, создайте и настройте дистанции
        </p>
      </NavLink>
    </Box>
  );
};
