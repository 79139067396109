import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getProfileCreateThunk = createAsyncThunk(
  "personalCabinet/profile",
  async (_, { rejectedWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.getProfile();
      return data;
    } catch (error) {
      return rejectedWithValue(error.response.data);
    }
  }
);
