import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const getAdminsAndVolunteers = createAsyncThunk(
  'getAdminsAndVolunteers/admin', async (_, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.getAdminsAndVolunteers();
      return response.data;
    } catch (error) {
      return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
    }
  }
)
