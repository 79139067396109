import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';
import {toast} from "react-toastify";

export const getVoucherPromocodesByVoucherId = createAsyncThunk(
  'admin/getVoucherPromocodesByVoucherId', async (
    voucherData: {
      page: number,
      pageSize: number,
      voucherId: number,
      selectedPromocodeStatus?: string,
      selectedDistanceName?: string,
    }, {rejectWithValue}
  ) => {
    try {
      const response = await typedAdminAPI.getVoucherPromocodesByVoucherId(voucherData);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 404) {
        toast.error('Ваучер не найден', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Ваучер не найден');
      }

      toast.error('Ой, что-то пошло не так, попробуйте обновить страницу', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
    }
  }
)
