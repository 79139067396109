import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {USER_LOGIN_PATH, USER_REGISTER_PATH} from 'pages/paths';
import Grid from "@mui/material/Grid";
import styles from './Anonymous.module.scss';

type AnonymousProp = {
  handleDrawerToggle: () => void;
}

const Anonymous: React.FC<AnonymousProp> = ({handleDrawerToggle}): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Grid sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px'}}>
      <Link onClick={handleDrawerToggle} to={USER_REGISTER_PATH} className={styles.registerLink}>
        {t('header.register')}
      </Link>
      <Link onClick={handleDrawerToggle} to={USER_LOGIN_PATH} className={styles.loginLink}>
        {t('header.signIn')}
      </Link>
    </Grid>
  );
};

export default Anonymous;
