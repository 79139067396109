import axiosApi from "shared/api/axiosApi";
import { toast } from "react-toastify";

export const downloadPromocodesExcelFile = async (voucherId) => {
    try {
      const response = await axiosApi.get(
        `/vouchers/${voucherId}/excel?isCorporateIssuance=false`,
        {
          headers: {
            "Accept-language": "en-us",
          },
          responseType: "blob"
        },
        
      );
      const href = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement("a");
      const fileName = decodeURI(response.headers["content-disposition"]).split(
        "="
      )[1];
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      toast.error("Ой! Что-то пошло не так, попробуйте обновить страницу...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        },
      });
    }
  };