import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

//delete the entire file and logic if it doesn't work by rtk

export const getDowloadFileCreateThunk = createAsyncThunk(
    "personalCabinet/dowloadFile",
    async (url, { rejectedWithValue }) => {

        try {
            const { data } = await personalCabinetAPI.getDowloadFile(url)
            return data
          } catch (error) {
            return rejectedWithValue(error.response.data);
          }
    }
)
