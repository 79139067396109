import { useState } from "react";
import { useCreateMarathonForm } from "../model/useCreateMarathonForm";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { MarathonNav } from "./MarathonNav/MarathonNav";
import MarathonCreatedModal from "entities/admin/ui/MarathonCreatedModal/MarathonCreatedModal";

export const MarathonForm = () => {
  const [isCreatedMarathonModalOpen, setCreatedMarathonModalOpen] =
    useState(false);

  const { form, handlecreateMarathon } = useCreateMarathonForm(
    setCreatedMarathonModalOpen
  );

  return (
    <FormProvider {...form}>
      <Box>
        <MarathonNav />
        <form onSubmit={form.handleSubmit(handlecreateMarathon)}>
          <Outlet />
        </form>
        <MarathonCreatedModal
          handleCreatedMarathonClose={() => setCreatedMarathonModalOpen(false)}
          isCreatedMarathonModalOpen={isCreatedMarathonModalOpen}
        />
      </Box>
    </FormProvider>
  );
};
