import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';
import {toast} from "react-toastify";

export const addPromocodesToVouchers = createAsyncThunk(
  'admin/addPromocodesToVouchers', async (voucherData: any, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.addPromocodesToVoucher(voucherData.voucherId, voucherData.promocodes);
      return response.data;
    } catch (error: any) {
      //в сваггере ещё отправляй запросы, там же ещё эксепшены
      //везде ошибки обрабатывать

      //документ в профиле может быть только в формтае jpg jpeg and png and size 20 mb
      if (error.response.data.status === 400 && error.response.data.code === 22) {
        return rejectWithValue(error.response.data.title);
      }

      if (error.response.status === 400) {
        return rejectWithValue('Убедитесь, что заполнили все поля');
      }

      if (error.response.status >= 500 && error.response.status <= 526) {
        return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
      }

      toast.error('Ой, что-то пошло не так, попробуйте обновить страницу', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
    }
  }
)
