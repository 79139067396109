import SwitchMui from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

export const inputDateStyle = {
  marginTop: "4px",
  width: "100%",
  borderRadius: "12px",
  "& .MuiOutlinedInput-input": {
    padding: "0 12px",
    height: "30px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

export const selectStyle = {
  marginTop: "4px",
  width: "100%",

  border: "1px solid #C0D4E3",
  borderRadius: "10px",
  padding: "0",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

export const phoneStyle = {
  containerStyle: {
    marginTop: "4px",
  },
  inputStyle: {
    width: "100%",
    borderRadius: "12px",
    height: "40px",
    padding: "0 12px 0 60px",
  },
  buttonStyle: {
    background: "none",
    borderRadius: "12px 0 0 12px",
    border: "none",
    padding: "5px",
  },
};

export const buttonFileStyle = {
  height: "40px",
  color: "#0090D1",
  backgroundColor: "#F0F8FF !important",
  borderRadius: "12px",
};

export const Switch = styled(SwitchMui)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#0090D1",
    "&:hover": {
      backgroundColor: alpha("#0090D1", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#0090D1",
  },
}));

export const suspenseProgressStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
  fontSize: "50px",
};
