import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const createUser = createAsyncThunk(
  'createUser/admin', async (userData: any, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.createUser(userData);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 400 && error.response.data.title === 'Пользователь уже существует') {
        return rejectWithValue(error.response.data.title)
      } else if (error.response.data.errors.Email[0].errorMessage === `'Email' неверный email адрес.`) {
        return rejectWithValue('Некорректный email адрес');
      } else {
        return rejectWithValue('Error');
      }
    }
  }
)
