import React from 'react';
import {NavLink} from "react-router-dom";
import {ADMIN_PANEL_PATH, MARATHONS_PATH} from "pages/paths";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from "@mui/material/Divider";
import {
  marathonCreatedModalButton, marathonCreatedModalText, marathonCreatedModalTitle
} from "entities/admin/lib/MUIStyles/MUIStyles";
import marathonCreatedIcon from 'assets/images/register/emailConfirmed.svg';
import styles from './MarathonCreatedModal.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 350,
    sm: 450,
    md: 450,
    lg: 450,
  },
  bgcolor: '#B0EEB7',
  pt: '60px',
  borderRadius: '16px',
  border: 'none',
  outline: 'none',
};

type Props = {
  isCreatedMarathonModalOpen: boolean,
  handleCreatedMarathonClose: () => void,
}

const MarathonCreatedModal: React.FC<Props> = (props): JSX.Element => {
  const {isCreatedMarathonModalOpen, handleCreatedMarathonClose} = props;

  return (
    <div>
      <Modal
        open={isCreatedMarathonModalOpen}
        onClose={handleCreatedMarathonClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.innerBox}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={marathonCreatedModalTitle}
            >
              Марафон успешно создан
            </Typography>
            <Typography
              sx={marathonCreatedModalText}
              id="modal-modal-description">
              Вы можете просмотреть список всех марафонов на странице мероприятий
            </Typography>
            <Divider/>
            <img src={marathonCreatedIcon} alt="Марафон успешно создан" style={{
              position: 'absolute',
              top: '-5px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}/>
            <Button
              variant="contained"
              sx={marathonCreatedModalButton}>
              <NavLink
                to={'/' + ADMIN_PANEL_PATH + '/' + MARATHONS_PATH}
                style={{color: '#fff'}}
                onClick={handleCreatedMarathonClose}
              >
                Перейти к списку марафонов
              </NavLink>
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MarathonCreatedModal;
