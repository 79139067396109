import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getStatusesCreateThunk = createAsyncThunk(
  "personalCabinet/getStatuses",
  async () => {
    try {
      const { data } = await personalCabinetAPI.getStatuses()
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);
