import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const deleteFileCreateThunk = createAsyncThunk(
    "personalCabinet/deleteFile",
    async (type, { rejectedWithValue }) => {
        try{
            const { data } = await personalCabinetAPI.deleteFile(type)
            return data;
        }catch(error){
            return rejectedWithValue(error.response.data);
        }
    }
)