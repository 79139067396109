import dayjs from "dayjs";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
type $FIXME = any;
let dateFormat = "DD-MM-YYYY";

export const fillFormData = (props: $FIXME) => {
  const formData = new FormData();
  if (props.id) {
    formData.append("Id", props.id);
  }

  formData.append(`Date`, dayjs(props.dateEvent).format(dateFormat));
  formData.append(
    "StartDateAcceptingApplications",
    dayjs(props.startDateAcceptingApplications).format(dateFormat)
  );
  formData.append(
    "EndDateAcceptingApplications",
    dayjs(props.endDateAcceptingApplications).format(dateFormat)
  );
  formData.append("IsActive", "true");

  for (let i = 0; i < props.documents.length; i++) {
    formData.append(`Documents`, props.documents[i]);
  }

  for (let i = 0; i < props.publicOffer.length; i++) {
    formData.append(
      `OfferAgreements[${i}].LanguageId`,
      props.publicOffer[i].languageId
    );
    formData.append(`OfferAgreements[${i}].File`, props.publicOffer[i].file[0]);
    if (typeof props.publicOffer[i].isUpdated === "boolean") {
      formData.append(
        `OfferAgreements[${i}].isUpdated`,
        props.publicOffer[i].isUpdated
      );
    }
  }

  for (let i = 0; i < props.translations.length; i++) {
    if (props.translations[i]?.id) {
      formData.append(`Translations[${i}].Id`, props.translations[i].id);
    }
    formData.append(`Translations[${i}].Name`, props.translations[i].name);
    formData.append(
      `Translations[${i}].Text`,
      draftToHtml(convertToRaw(props.translations[i].text?.getCurrentContent()))
    );
    formData.append(`Translations[${i}].Place`, props.translations[i].place);
    formData.append(
      `Translations[${i}].LanguageId`,
      props.translations[i].languageId
    );
    formData.append(
      `Translations[${i}].StartKitMessage`,
      props.translations[i].startKitMessage
    );
    formData.append(
      `Translations[${i}].LanguageId`,
      props.translations[i].languageId
    );
    formData.append(`Translations[${i}].Logo`, props.translations[i].logo[0]);
  }

  for (let i = 0; i < props.partners.length; i++) {
    if (props.partners[i].id) {
      if (props.isUpdating) {
        if (typeof props.partners[i].id === "string") {
          formData.append(`Partners[${i}].Id`, "0");
        } else {
          formData.append(`Partners[${i}].Id`, props.partners[i].id);
        }
      }
    }
    formData.append(
      `Partners[${i}].SerialNumber`,
      props.partners[i].serialNumber
    );
    const translations = props.partners[i].translations;
    const partnerCompanies = props.partners[i].partnerCompanies;

    for (let x = 0; x < partnerCompanies.length; x++) {
      if (partnerCompanies[x].id) {
        formData.append(
          `Partners[${i}].PartnerCompanies[${x}].Id`,
          partnerCompanies[x].id
        );
      }
      formData.append(
        `Partners[${i}].PartnerCompanies[${x}].Logo`,
        partnerCompanies[x].logo[0]
      );
      formData.append(
        `Partners[${i}].PartnerCompanies[${x}].Name`,
        partnerCompanies[x].name
      );
      formData.append(
        `Partners[${i}].PartnerCompanies[${x}].Url`,
        partnerCompanies[x].url
      );
    }

    for (let x = 0; x < translations.length; x++) {
      formData.append(
        `Partners[${i}].Translations[${x}].Name`,
        translations[x].name
      );
      formData.append(
        `Partners[${i}].Translations[${x}].LanguageId`,
        translations[x].languageId
      );
      if (translations[x].id) {
        formData.append(
          `Partners[${i}].Translations[${x}].Id`,
          translations[x].id
        );
      }
    }
  }

  formData.append("InsurancePrice", props.insurancePrice);

  for (let i = 0; i < props.distances.length; i++) {
    const distancePrices = props.distances[i].distancePrices;
    const distanceAges = props.distances[i].distanceAges;

    if (props.distances[i].id) {
      formData.append(`Distances[${i}].Id`, props.distances[i].id);
    }
    formData.append(`Distances[${i}].Name`, props.distances[i].name);
    formData.append(
      `Distances[${i}].HasInsurance`,
      props.distances[i].hasInsurance
    );
    formData.append(
      `Distances[${i}].StartNumbersFrom`,
      props.distances[i].startNumbersFrom as Blob | string
    );
    formData.append(
      `Distances[${i}].StartNumbersTo`,
      props.distances[i].startNumbersTo as Blob | string
    );

    for (let x = 0; x < distancePrices.length; x++) {
      formData.append(
        `Distances[${i}].DistancePrices[${x}].DateStart`,
        dayjs(distancePrices[x].dateStart).format(dateFormat)
      );
      formData.append(
        `Distances[${i}].DistancePrices[${x}].DateEnd`,
        dayjs(distancePrices[x].dateEnd).format(dateFormat)
      );
      formData.append(
        `Distances[${i}].DistancePrices[${x}].Price`,
        distancePrices[x].price
      );
      if (distancePrices[x].newId) {
        formData.append(
          `Distances[${i}].DistancePrices[${x}].Id`,
          distancePrices[x].newId
        );
      }
    }

    for (let x = 0; x < distanceAges.length; x++) {
      formData.append(
        `Distances[${i}].DistanceAges[${x}].Gender`,
        distanceAges[x].gender as string | Blob
      );
      formData.append(
        `Distances[${i}].DistanceAges[${x}].AgeFrom`,
        distanceAges[x].ageFrom
      );
      formData.append(
        `Distances[${i}].DistanceAges[${x}].AgeTo`,
        distanceAges[x].ageTo
      );
      if (distanceAges[x].newId) {
        formData.append(
          `Distances[${i}].DistanceAges[${x}].Id`,
          distanceAges[x].newId
        );
      }
    }
  }

  return formData;
};