import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";

export const getCorporateClientsByMarathonIdThunk = createAsyncThunk(
  "admin/getClientsByMarathonId",
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await typedAdminAPI.getCorporateClientsByMarathonId(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
