import {createAsyncThunk} from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getMyResultAsPDF = createAsyncThunk('personalCabinet/getMyResultAsPDF',
  async (resultId) => {
    try {
      const response = await personalCabinetAPI.getMyResultAsPDF(resultId);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  });
