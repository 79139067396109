import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const EmailConfirmationSkeleton:React.FC = (): JSX.Element => {
  return (
    <Grid item sx={{
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      }
    }}>
      <Skeleton animation="pulse" variant="rectangular" sx={{
        width: '50%',
        minHeight: '91.9vh',
        '@media screen and (max-width: 767px)': {
          flexDirection: 'column',
          minHeight: '35vh',
          width: '100%'
        }
      }}/>
      <Stack sx={{
          width: '30%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '@media screen and (max-width: 1130px)': {
            width: '40%',
          },
          '@media screen and (max-width: 767px)': {
            height: '50vh',
            width: '60%',
          },
          '@media screen and (max-width: 560px)': {
            width: '80%',
          }
        }}>
        <Skeleton
          animation="pulse"
          variant="text"
          width='80%'
          sx={{
            fontSize: '32px',
            marginBottom: '15px',
          }}/>
        <Skeleton
          animation="pulse"
          variant="text"
          width='100%'
          sx={{
            fontSize: '26px',
            marginBottom: '-5px',
          }}/>
        <Skeleton
          animation="pulse"
          variant="text"
          width='85%'
          sx={{
            fontSize: '26px',
            marginBottom: '25px',
          }}/>
        <Skeleton
          animation="pulse"
          variant="text"
          width='50%'
          sx={{
            fontSize: '26px'
          }}/>
      </Stack>
    </Grid>
  );
};

export default EmailConfirmationSkeleton;
