import { Box } from "@mui/material";
import { LoadDocument } from "../LoadFile/loadDocument";
import { LoadImage } from "../LoadFile/loadImage";
import { LoadPublicoffer } from "../LoadFile/loadPublicOffer";
import { P1 } from "../LoadFile/Parthner/Parthners";
import { TextAreaDescription } from "../LoadFile/textAreaDescription";
import { UiDatetField } from "shared/ui/UiDatePicker/UiDateField";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import dayjs from "dayjs";
import { useMarathonTabForm } from "features/createMarathon/model/MarathonTabs/useMarathonTabForm";

export const KgTab = () => {
  const {
    data,
    register,
    fields,
    appendDocument,
    control,
    setValue,
    removeDocument,
    resetField,
    appendPublicOffer,
    replacePublicOffer,
    removePublicOffer,
    insertPublicOffer,
    appendPartners,
    clearErrors,
    removePartners,
    fieldsPartners,
    errors,
    updatePublickOffer,
  } = useMarathonTabForm();

  const indexLanguage = 2;
  const languageId = 3;
  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <UiTextField
        placeholder="Введите название марафона"
        label="Название"
        inputProps={{
          ...register(`translations.${indexLanguage}.name`),
        }}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.name?.message
            : ""
        }
      />
      <UiDatetField
        minDate={dayjs()}
        control={control}
        placeholder="Выберите дату"
        label="Дата проведения"
        name="dateEvent"
      />
      <UiTextField
        inputProps={{
          ...register(`translations.${indexLanguage}.place`),
        }}
        placeholder="Введите место проведения марафона"
        label="Место проведения"
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.place?.message
            : ""
        }
      />
      <UiTextField
        inputProps={{
          ...register(`translations.${indexLanguage}.startKitMessage`),
        }}
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.startKitMessage?.message
            : ""
        }
        placeholder="Введите информацию по составу пакета"
        label="Состав пакета"
      />
      <LoadImage
        error={
          errors.translations
            ? errors.translations[indexLanguage]?.logo?.message
            : ""
        }
        indexLanguage={indexLanguage}
        data={data}
        setValue={setValue}
        languageId={indexLanguage}
      />
      <LoadPublicoffer
        updatePublickOffer={updatePublickOffer}
        replacePublicOffer={replacePublicOffer}
        insertPublicOffer={insertPublicOffer}
        indexLanguage={indexLanguage}
        languageId={languageId}
        appendPublicOffer={appendPublicOffer}
        data={data}
        removePublicOffer={removePublicOffer}
      />
      <TextAreaDescription
        indexLanguage={indexLanguage}
        placeholder="Введите описание марафона"
        handleChange={(text: string) => {
          setValue(`translations.${indexLanguage}.text`, text);
          clearErrors(`translations.${indexLanguage}.text`);
        }}
        value={data.translations[indexLanguage]?.text}
      />
      <LoadDocument
        append={appendDocument}
        data={data}
        removeDocument={removeDocument}
      />
      <P1
        control={control}
        indexLanguage={indexLanguage}
        append={appendPartners}
        fields={fieldsPartners}
        data={data}
        register={register}
        remove={removePartners}
      />
    </Box>
  );
};
