import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";

export const getCorporateClientsThunk = createAsyncThunk(
  "admin/getClients",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await typedAdminAPI.getCorporateClients();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }

    // "personalCabinet/getClients"
    // async (_: any, { rejectWithValue }: any) => {
    //   try {
    //     const data  = await personalCabinetAPI.getCorporateClients();
    //     return data;
    //   } catch (error) {
    //     return rejectWithValue(error);

    //   }
    // }
  }
);
