import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import { toast } from "react-toastify";

export const getMarathons = createAsyncThunk(
  "admin/getMarathons",
  async (
    { corporateClientId }: { corporateClientId?: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await typedAdminAPI.getMarathons(corporateClientId);

      return data;
    } catch (error: any) {
      toast.error("Ой, что-то пошло не так, попробуйте обновить страницу", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue(
        "Ой, что-то пошло не так, попробуйте обновить страницу"
      );
    }
  }
);
