import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const getStatusComments = createAsyncThunk(
  "admin/getStatusComments",
  async () => {
    try {
      const { data } = await adminAPI.getStatusComments()
      return data;
    } catch (error) {
      console.log("Error while getting list of Marathons", error);
    }
  }
);
