import { Box, Divider, Typography } from "@mui/material";

export const LineTitle = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "16px",
        mt: "32px",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          fontFamily: "Raleway",
        }}
      >
        {title}
      </Typography>
      <Divider sx={{ flex: "1", background: "#ECF2F7" }} />
    </Box>
  );
};
