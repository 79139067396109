import React, {useState} from 'react';
import {useAppSelector} from 'shared/lib/hooks/hooks';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {USER_LOGIN_PATH, USER_REGISTER_PATH} from 'pages/paths';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NavItems from 'shared/ui/NavItems/NavItems';
import UserDropDownMenu from 'shared/ui/UserDropDownMenu/UserDropDownMenu';
import LanguageDropDown from 'shared/ui/LanguageDropDown/LanguageDropDown';
import SwipeDrawer from 'shared/ui/SwipeableDrawer/SwipeDrawer';
import DrawerDropDownMenu from 'entities/viewer/ui/drawerDropDownMenu/DrawerDropDownMenu';
import DrawerNavItems from 'entities/user/ui/DrawerNavItems/DrawerNavItems';
import HeaderInsuranceBanner from "../../shared/ui/NavItems/HeaderInsuranceBanner";
import Anonymous from 'entities/user/ui/Anonymous/Anonymous';
import marathonLogo from 'assets/images/header/marathonLogo.svg';
import styles from './Header.module.scss';

const theme = createTheme({
  zIndex: {appBar: 1},
  breakpoints: {
    values: {
      xs: 0,
      sm: 1101,
      md: 1000,
      lg: 1200,
      xl: 1500,
    },
  },
});

interface HeaderProp {
  window?: () => Window;
}

const Header: React.FC<HeaderProp> = (props): JSX.Element =>  {
  const {window} = props;
  const isAuth = useAppSelector(state => state.users.isAuth);
  const [nestedMenuOpen, setNestedMenuOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const token = localStorage.getItem('AccessToken');
  const {t} = useTranslation();

  const nestedMenuHandleClick = () => {
    setNestedMenuOpen(!nestedMenuOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{textAlign: 'center'}}>
      {token && isAuth ? (
        <DrawerDropDownMenu
          nestedMenuHandleClick={nestedMenuHandleClick}
          nestedMenuOpen={nestedMenuOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      ) : (
        <Anonymous handleDrawerToggle={handleDrawerToggle}/>
      )}
      <Divider/>
      <DrawerNavItems handleDrawerToggle={handleDrawerToggle}/>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <div className={styles.headerContainer}>
        <div className='container'>
          {token && isAuth && <HeaderInsuranceBanner />}
        </div>
        <div className="container" style={{paddingTop: token && isAuth ? '72px' : '0'}}>
          <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex'}}>
              <AppBar component="nav" sx={{
                backgroundColor: '#fff', boxShadow: 'none', position: 'static'
                }}>
                <Toolbar style={{padding: '0'}}>
                  <div style={{display: 'flex', flexGrow: 1}}>
                    <Typography
                      variant="h6"
                      sx={{marginRight: 'auto', display: {sm: 'block'}}}>
                      <a href='https://runthesilkroad.com/' target="_blank" rel="noreferrer" className={styles.headerLogo}>
                        <img src={marathonLogo} alt="Логотип Run the Silk Road"/>
                        <span className={styles.marathonHeader}>Run The Silk Road</span>
                      </a>
                    </Typography>
                  </div>
                  <NavItems t={t}/>
                  {token && isAuth ? <UserDropDownMenu/> : (
                    <Grid sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Link to={USER_REGISTER_PATH} className={styles.registerLink + ' ' + styles.registerLinkNone}>
                        {t("header.register")}
                      </Link>
                      <Link to={USER_LOGIN_PATH} className={styles.loginLink + ' ' + styles.loginLinkNone}>
                        {t("header.signIn")}
                      </Link>
                    </Grid>
                  )}
                  <LanguageDropDown/>
                  {mobileOpen ? null : (
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ml: '5px', display: {sm: 'none'}}}
                    >
                      <MenuIcon sx={{fontSize: 35}}/>
                    </IconButton>
                  )}
                </Toolbar>
              </AppBar>
              <SwipeDrawer
                container={container}
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                drawer={drawer}
              />
            </Box>
          </ThemeProvider>
        </div>
      </div>
  );
};

export default Header;
