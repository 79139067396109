import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";

export const getPublicOfferCreateThunk = createAsyncThunk(
  "personalCabinet/offer",
  async (id, { rejectedWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.getPublicOffer(id);
      return data.documentPath;
    } catch (er) {
      return rejectedWithValue(er.response.data);
    }
  }
);
