import { PERSONAL_CABINET_PATH, PROFILE_PATH } from "pages/paths";

import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPaths } from "shared/lib/createPaths";
import history from "shared/lib/history";
import { personalCabinetAPI } from './../api/index';

export const putProfileCreateThunk = createAsyncThunk(
    "personalCabinet/profileEdit",
    async (userData, { rejectWithValue }) => {
        try{
            const { data } = await personalCabinetAPI.putProfileEdit(userData)
            history.push(createPaths(PERSONAL_CABINET_PATH, PROFILE_PATH)) 
            return data;
        }catch(error){
            if (error.response.data.status === 400) {
                return rejectWithValue(error.response.data.detail);
            }
        }
    }
)