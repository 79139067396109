import {createAsyncThunk} from "@reduxjs/toolkit";
import {personalCabinetAPI} from "../api";

export const postSubVoucherCreateThunk = createAsyncThunk(
  'personalCabinet/postSubVoucherCreateThunk',
  async ({subVoucherData,corporateClientId}: any,{rejectWithValue}) => {
    try {
      const {data} = await personalCabinetAPI.postSubVoucher({subVoucherData,corporateClientId})
      return data
    } catch (error: any) {
      if (error.response.data.status === 400) {
        return rejectWithValue(error.response.data.title);
      } else {
        return rejectWithValue(error);
      }
    }
  }
)
