import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import {
  CHANGE_PASSWORD_PATH,
  EDIT_PATH,
  INSURANCE_PATH,
  MY_DOCUMENTS_PATH,
  MY_MARATHON_PATH,
  MY_RESULTS_PATH,
  PASSPORT_PATH,
  PROFILE_PATH,
  MAIN_PAGE_PATH,
  PAGE_NOT_FOUND,
  USER_PATH,
  USER_CHANGE_PASSWORD_PATH,
  USER_EMAIL_CONFIRMATION_PATH,
  USER_FORGOT_PASSWORD_PATH,
  USER_LOGIN_PATH,
  USER_REGISTER_PATH,
  USER_REGISTER_CONFIRM_EMAIL_PATH,
  USER_RESET_PASSWORD_PATH,
  PERSONAL_CABINET_PATH,
  PERSON_HANDICAPPED_PATH,
  ADMIN_PANEL_PATH,
  STEP2_SETTINGS_PATH,
  STEP1_CONTENT_PATH,
  MARATHONS_PATH,
  VOUCHERS_PATH,
  VOUCHERS_PROMOCODES_PATH,
  STARTER_KIT_PATH,
  USERS_PATH,
  ALL_USERS_PATH,
  DISABLED_PEOPLE_PATH,
  ACTIVE_MARATHON_PARTICIPANTS_PATH,
  MARATHON_RESULTS,
  GENERAL_MARATHON_RESULTS,
  MY_ORGANIZATION_PATH,
  VOUCHERS_ADMIN_PATH,
  NOTIFICATIONS_PATH,
} from "./paths";
import CircularProgress from "@mui/material/CircularProgress";
import RequireAuth from "../shared/lib/RequireAuth/RequireAuth";

import RegisterSkeleton from "../shared/ui/Skeletons/RegisterSkeleton/RegisterSkeleton";
import SignInSkeleton from "../shared/ui/Skeletons/SignInSkeleton/SignInSkeleton";
import ForgotPasswordSkeleton from "../shared/ui/Skeletons/ForgotPasswordSkeleton/ForgotPasswordSkeleton";
import UserChangePasswordSkeleton from "../shared/ui/Skeletons/UserChangePasswordSkeleton/UserChangePasswordSkeleton";
import EmailConfirmationSkeleton from "../shared/ui/Skeletons/EmailConfirmationSkeleton/EmailConfirmationSkeleton";
import ResetPasswordSkeleton from "../shared/ui/Skeletons/ResetPasswordSkeleton/ResetPasswordSkeleton";
import Layout from "../shared/ui/Layout/Layout";

import { createPaths } from "shared/lib/createPaths";
import { suspenseProgressStyles } from "../shared/lib/style";
import ActiveMarathonParticipants from "../shared/ui/ActiveMarathonParticipants/ActiveMarathonParticipants";
import MarathonResults from "../entities/admin/ui/MarathonResults/MarathonResults";
import AccessibleToAllMarathonResults from "../shared/ui/AccessibleToAllMarathonResults/AccessibleToAllMarathonResults";
import SubVoucher from "../entities/viewer/ui/personalCabinet/SubVoucher/SubVoucher";
import SubVoucherAdmin from "entities/admin/ui/SubVoucherAdmin/SubVoucherAdmin";
import { MarathonContent } from "features/createMarathon/ui/StepTabs/MarathonContent";
import { MarathonSettings } from "features/createMarathon/ui/StepTabs/MarathonSettings";
import CreateMarathonNew from "./CreateMarathon/CreateMarathon";

const Register = lazy(() => import("../entities/user/ui/Register/Register"));
const SignIn = lazy(() => import("../entities/user/ui/SignIn/SignIn"));
const CreateUser = lazy(() =>
  import("../entities/admin/ui/CreateUser/CreateUser")
);
const EmailConfirmation = lazy(() =>
  import("../entities/user/ui/EmailConfirmation/EmailConfirmation")
);
const ForgotPassword = lazy(() =>
  import("../entities/user/ui/ForgotPassword/ForgotPassword")
);
const UserChangePassword = lazy(() =>
  import("../entities/user/ui/UserChangePassword/UserChangePassword")
);
const PersonHandicapped = lazy(() =>
  import(
    "./../entities/viewer/ui/personalCabinet/personHandicapped/personHandicapped"
  )
);
const EditPersonHandicapped = lazy(() =>
  import(
    "./../entities/viewer/ui/personalCabinet/editPersonHandicapped/editPersonHandicapped"
  )
);
const EditSpecificParticipantContainer = lazy(() =>
  import(
    "../entities/admin/ui/EditSpecificParticipantContainer/EditSpecificParticipantContainer"
  )
);
const VoucherListTable = lazy(() =>
  import("../entities/admin/ui/Vouchers/VoucherListTable/VoucherListTable")
);
const SpecificParticipant = lazy(() =>
  import("../entities/admin/ui/SpecificParticipant/SpecificParticipant")
);
const Vouchers = lazy(() => import("../entities/admin/ui/Vouchers/Vouchers"));
const ParticipantsList = lazy(() =>
  import("../entities/admin/ui/ParticipantsList/ParticipantsList")
);
const StarterKit = lazy(() =>
  import("../entities/admin/ui/StarterKit/StarterKit")
);
const CreateMarathonSettings = lazy(() =>
  import("../entities/admin/ui/CreateMarathonSettings/CreateMarathonSettings")
);
const Marathons = lazy(() =>
  import("../entities/admin/ui/marathons/marathons")
);
const PageNotFound = lazy(() => import("./PageNotFound/PageNotFound"));
const CreateMarathon = lazy(() =>
  import("../entities/admin/ui/createMarathon/createMarathon")
);
const UserEditContainer = lazy(() =>
  import("../entities/admin/ui/userEditContainer/userEditContainer")
);
const AllUsers = lazy(() => import("../entities/admin/ui/allUsers/allUsers"));
const StepContent = lazy(() =>
  import("../entities/admin/ui/stepContent/stepContent")
);

const EditMyMarathon = lazy(() => import("./EditMarathon/EditMarathon"));

const DisabledPeople = lazy(() =>
  import("../entities/admin/ui/disabledPeople/disabledPeople")
);
const UsersWrapp = lazy(() =>
  import("../entities/admin/ui/usersWrapp/usersWrapp")
);
const DetailMarathon = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/detailMarathon/detailMarathon")
);
const UserDetail = lazy(() =>
  import("../entities/admin/ui/userDetail/userDetail")
);
const MyDocument = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/myDocument/myDocument")
);
const PersonalCabinet = lazy(() =>
  import(
    "../entities/viewer/ui/personalCabinet/personalCabinet/personalСabinet"
  )
);
const ChangePassword = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/changePassword/changePassword")
);
const Passport = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/passport/passport")
);
const EditPassport = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/editPassport/editPassport")
);
const Profile = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/profile/profile")
);
const UserVouchers = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/myOrganization/MyOrganization")
);
const MyOrganization = lazy(() => import("./MyOrganization/MyOrgazination"));
const EditProfile = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/editProfile/editProfile")
);
const MyResult = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/myResult/myResult")
);
const EditInsurance = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/editInsurance/editInsurance")
);
const Insurance = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/insurance/insurance")
);
const MyMarathon = lazy(() =>
  import("../entities/viewer/ui/personalCabinet/myMarathon/myMarathon")
);
const ConfirmEmail = lazy(() =>
  import("../entities/user/ui/ConfirmEmail/ConfirmEmail")
);
const ResetPassword = lazy(() =>
  import("../entities/user/ui/ResetPassword/ResetPassword")
);

const Notifications = lazy(() => import("./Notifications/Notifications"));
const AllUsersNotifications = lazy(() =>
  import("./Notifications/AllUsersNotifications/AllUsersNotifications")
);
const DisabledUsersNotifications = lazy(() =>
  import(
    "./Notifications/DisabledUsersNotifications/DisabledUsersNotifications"
  )
);

const Routing = () => {
  const routes = createBrowserRouter([
    {
      path: MAIN_PAGE_PATH,
      element: <Layout />,
      children: [
        {
          path: USER_PATH,
          children: [
            {
              path: USER_REGISTER_PATH,
              children: [
                {
                  path: "",
                  element: (
                    <Suspense fallback={<RegisterSkeleton />}>
                      <Register />
                    </Suspense>
                  ),
                },
                {
                  path: USER_REGISTER_CONFIRM_EMAIL_PATH,
                  element: (
                    <Suspense
                      fallback={
                        <CircularProgress sx={suspenseProgressStyles} />
                      }
                    >
                      <ConfirmEmail />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: USER_LOGIN_PATH,
              element: (
                <Suspense fallback={<SignInSkeleton />}>
                  <SignIn />
                </Suspense>
              ),
            },
            {
              path: USER_EMAIL_CONFIRMATION_PATH,
              element: (
                <Suspense fallback={<EmailConfirmationSkeleton />}>
                  <EmailConfirmation />
                </Suspense>
              ),
            },
            {
              path: USER_FORGOT_PASSWORD_PATH,
              element: (
                <Suspense fallback={<ForgotPasswordSkeleton />}>
                  <ForgotPassword />
                </Suspense>
              ),
            },
            {
              path: USER_RESET_PASSWORD_PATH,
              element: (
                <Suspense fallback={<ResetPasswordSkeleton />}>
                  <ResetPassword />
                </Suspense>
              ),
            },
            {
              path: USER_CHANGE_PASSWORD_PATH,
              element: (
                <Suspense fallback={<UserChangePasswordSkeleton />}>
                  <UserChangePassword />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ACTIVE_MARATHON_PARTICIPANTS_PATH,
          element: (
            <Suspense
              fallback={<CircularProgress sx={suspenseProgressStyles} />}
            >
              <ActiveMarathonParticipants />
            </Suspense>
          ),
        },
        {
          path: GENERAL_MARATHON_RESULTS,
          element: (
            <Suspense
              fallback={<CircularProgress sx={suspenseProgressStyles} />}
            >
              <AccessibleToAllMarathonResults />
            </Suspense>
          ),
        },
        {
          path: PERSONAL_CABINET_PATH,
          element: (
            <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <PersonalCabinet />
              </Suspense>
            </RequireAuth>
          ),
          children: [
            {
              path: PROFILE_PATH,
              children: [
                {
                  path: "",
                  element: (
                    <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                      <Suspense
                        fallBack={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <Profile />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
                {
                  path: "edit",
                  element: (
                    <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <EditProfile />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: CHANGE_PASSWORD_PATH,
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <ChangePassword />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: MY_DOCUMENTS_PATH + "/*",
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MyDocument />
                  </Suspense>
                </RequireAuth>
              ),
              children: [
                {
                  path: PASSPORT_PATH,
                  children: [
                    {
                      path: "",
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <Passport />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                    {
                      path: EDIT_PATH,
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <EditPassport />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                  ],
                },
                {
                  path: INSURANCE_PATH,
                  children: [
                    {
                      path: "",
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <Insurance />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                    {
                      path: EDIT_PATH,
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <EditInsurance />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                  ],
                },
                {
                  path: PERSON_HANDICAPPED_PATH,
                  children: [
                    {
                      path: "",
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <PersonHandicapped />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                    {
                      path: EDIT_PATH,
                      element: (
                        <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <EditPersonHandicapped />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                  ],
                },
                { path: "*", element: <Navigate to={PASSPORT_PATH} /> }, //It is needed in order for the NavLink work
              ],
            },
            {
              path: MY_MARATHON_PATH,
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MyMarathon />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: MY_RESULTS_PATH,
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MyResult />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: MY_ORGANIZATION_PATH,
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MyOrganization />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: `${MY_ORGANIZATION_PATH}/:corporateClientId`,
              element: (
                <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    {/* <SubVoucher /> */}
                    <UserVouchers />
                  </Suspense>
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: `${PERSONAL_CABINET_PATH}/${MY_ORGANIZATION_PATH}/:corporateClientId/sub_vouchers/:subVoucherId`,
          element: (
            <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <SubVoucher />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: PERSONAL_CABINET_PATH + "/" + VOUCHERS_PROMOCODES_PATH + "/:id",
          element: (
            <RequireAuth allowedRoles={["User"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <VoucherListTable />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: createPaths(PERSONAL_CABINET_PATH, MY_MARATHON_PATH, ":id"),
          element: (
            <RequireAuth allowedRoles={["User", "Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <DetailMarathon />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: ADMIN_PANEL_PATH,
          children: [
            {
              path: NOTIFICATIONS_PATH,
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <Notifications />
                  </Suspense>
                </RequireAuth>
              ),
              children: [
                {
                  path: ALL_USERS_PATH,
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <AllUsersNotifications />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
                {
                  path: DISABLED_PEOPLE_PATH,
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <DisabledUsersNotifications />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: MARATHONS_PATH,
              children: [
                {
                  path: "",
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner", "Volunteer"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <Marathons />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <CreateMarathon />
                      </Suspense>
                    </RequireAuth>
                  ),
                  children: [
                    {
                      path: STEP1_CONTENT_PATH + "/:language",
                      element: (
                        <RequireAuth allowedRoles={["Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <StepContent />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                    {
                      path: STEP2_SETTINGS_PATH,
                      element: (
                        <RequireAuth allowedRoles={["Admin", "Owner"]}>
                          <Suspense
                            fallback={
                              <CircularProgress sx={suspenseProgressStyles} />
                            }
                          >
                            <CreateMarathonSettings />
                          </Suspense>
                        </RequireAuth>
                      ),
                    },
                    {
                      path: "*",
                      element: <Navigate to={STEP1_CONTENT_PATH + "/ru"} />,
                    }, //It is needed in order for the NavLink work
                  ],
                },
              ],
            },
            {
              path: USERS_PATH,
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <UsersWrapp />
                  </Suspense>
                </RequireAuth>
              ),
              children: [
                {
                  path: ALL_USERS_PATH,
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <AllUsers />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
                {
                  path: DISABLED_PEOPLE_PATH,
                  element: (
                    <RequireAuth allowedRoles={["Admin", "Owner"]}>
                      <Suspense
                        fallback={
                          <CircularProgress sx={suspenseProgressStyles} />
                        }
                      >
                        <DisabledPeople />
                      </Suspense>
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: VOUCHERS_PATH,
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <Vouchers />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: VOUCHERS_PATH + "/:id",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <VoucherListTable />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: VOUCHERS_PROMOCODES_PATH + "/:id",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <VoucherListTable />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path:
                VOUCHERS_ADMIN_PATH +
                "/:voucherId/:marathonName/:voucherName/:corporateClientName",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <SubVoucherAdmin />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: STARTER_KIT_PATH,
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner", "Volunteer"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <StarterKit />
                  </Suspense>
                </RequireAuth>
              ),
            },
          ],
        },

        {
          path: createPaths(ADMIN_PANEL_PATH, USERS_PATH, ":typeUser", ":id"),
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <UserDetail />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: createPaths(
            ADMIN_PANEL_PATH,
            USERS_PATH,
            ":typeUser",
            ":id",
            EDIT_PATH
          ),
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <UserEditContainer />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: PAGE_NOT_FOUND,
          element: (
            <Suspense
              fallback={<CircularProgress sx={suspenseProgressStyles} />}
            >
              <PageNotFound />
            </Suspense>
          ),
        },
        {
          path: "/",
          element: <Navigate to={USER_REGISTER_PATH} />,
        },
        {
          path: "/marathons/listOfParticipants/:id",
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner", "Volunteer"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <ParticipantsList />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: "/marathons/listOfParticipants/participant/:id",
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner", "Volunteer"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <SpecificParticipant />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: "marathons/listOfParticipants/participant/:id/edit",
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <EditSpecificParticipantContainer />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: "/admin_panel/roles",
          element: (
            <RequireAuth allowedRoles={["Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <CreateUser />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: MARATHON_RESULTS + ":id",
          element: (
            <RequireAuth allowedRoles={["Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <MarathonResults />
              </Suspense>
            </RequireAuth>
          ),
        },
        {
          path: "/new",
          element: (
            <RequireAuth allowedRoles={["Admin", "Owner"]}>
              <Suspense
                fallback={<CircularProgress sx={suspenseProgressStyles} />}
              >
                <CreateMarathonNew />
              </Suspense>
            </RequireAuth>
          ),
          children: [
            {
              path: "step-1",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MarathonContent />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: "step-2",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MarathonSettings />
                  </Suspense>
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "/marathon/edit/:id",
          element: <EditMyMarathon />,
          children: [
            {
              path: "step-1",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MarathonContent />
                  </Suspense>
                </RequireAuth>
              ),
            },
            {
              path: "step-2",
              element: (
                <RequireAuth allowedRoles={["Admin", "Owner"]}>
                  <Suspense
                    fallback={<CircularProgress sx={suspenseProgressStyles} />}
                  >
                    <MarathonSettings />
                  </Suspense>
                </RequireAuth>
              ),
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default Routing;
