import { createAsyncThunk } from "@reduxjs/toolkit";
import { PERSONAL_CABINET_PATH, PROFILE_PATH } from "pages/paths";
import { createPaths } from "shared/lib/createPaths";
import history from "shared/lib/history";
import { personalCabinetAPI } from "./../api/index";

export const postChangePasswordCreateThunk = createAsyncThunk(
  "personalCabinet/changePassword",
  async (userPasswords, { rejectWithValue }) => {
    try {
      const { data } = await personalCabinetAPI.postChangePassword(
        userPasswords
      );
      history.push(createPaths(PERSONAL_CABINET_PATH, PROFILE_PATH))
      return data;
    } catch (error) {
      if (error.response.data.status === 400) {
        return rejectWithValue(error.response.data.title);
      }
    }
  }
);
