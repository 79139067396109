export const userDropDownMenuPaperProps = {
  sx: {
    borderRadius: '16px',
    padding: '10px',
    mr: '40px',
  },
};

export const userDropDownMenuTransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const userDropDownMenuItem = {
  width: '100%',
  '&:hover': {backgroundColor: '#F0F8FF', borderRadius: '8px'},
};
