//LOGIN
export const MAIN_PAGE_PATH = "/";
export const USER_PATH = "/user";
export const USER_REGISTER_PATH = "/user/register";
export const USER_LOGIN_PATH = "/user/login";
export const USER_EMAIL_CONFIRMATION_PATH = "/user/emailConfirmation";
export const USER_FORGOT_PASSWORD_PATH = "/user/forgotPassword";
export const USER_RESET_PASSWORD_PATH = "/user/resetPassword";
export const USER_CHANGE_PASSWORD_PATH = "/user/changePassword";
export const USER_REGISTER_CONFIRM_EMAIL_PATH = "/user/register/confirmEmail";
//PERSONAL_CABINET
export const PERSONAL_CABINET_PATH = "personal_cabinet";
export const CHANGE_PASSWORD_PATH = "change_password";
export const PROFILE_PATH = "profile";
export const EDIT_PATH = "edit";
export const MY_DOCUMENTS_PATH = "my_documents";
export const PASSPORT_PATH = "passport";
export const INSURANCE_PATH = "insurance";
export const PERSON_HANDICAPPED_PATH = "handicapped_person";
export const MY_MARATHON_PATH = "my_marathon";
export const MY_RESULTS_PATH = "my_results";

export const MY_ORGANIZATION_PATH = "my_organization";
export const SUB_VOUCHER_PATH = "sub_voucher";

//ADMIN_PANEL
export const ADMIN_PANEL_PATH = "admin_panel";
export const MARATHONS_PATH = "marathons";
export const CREATE_MARATHON_PATH = "create_marathon";
export const STEP1_CONTENT_PATH = "step1_content";
export const STEP2_SETTINGS_PATH = "step2_settings";
export const ADMIN_CREATE_MARATHON_PATH =
  "/admin_panel/marathons/create_marathon/step1_content/ru"; //FIX ???( createPaths )
export const USERS_PATH = "users";
export const ALL_USERS_PATH = "all_users";
export const DISABLED_PEOPLE_PATH = "disabled_people";
export const NOT_VERIFIED_PATH = "not_verified";
export const VERIFIED_PATH = "verified";
export const VOUCHERS_PATH = "vouchers";
export const VOUCHERS_ADMIN_PATH = "vouchers_admin";
export const VOUCHERS_PROMOCODES_PATH = "vouchers/promocodes";
export const STARTER_KIT_PATH = "starter_kit";
export const PARTICIPANTS_LIST = "listOfParticipants";
export const MARATHON_RESULTS = "/marathon/results/";
export const NOTIFICATIONS_PATH = "notifications";
export const ROLES = "roles";
//General page for not authorized user and authorized
export const ACTIVE_MARATHON_PARTICIPANTS_PATH = "users/participants";
export const GENERAL_MARATHON_RESULTS = "marathon/results";

//404 PAGE
export const PAGE_NOT_FOUND = "*";
