import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const ForgotPasswordSkeleton: React.FC = (): JSX.Element => {
  return (
    <Grid item sx={{
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      }
    }}>
      <Skeleton
        animation="pulse"
        variant="rectangular"
        sx={{
          width: '50%',
          minHeight: '91.9vh',
          '@media screen and (max-width: 767px)': {
            flexDirection: 'column',
            minHeight: '35vh',
            width: '100%',
            marginBottom: '50px',
          }
        }}
      />
      <Stack sx={{
        display: 'flex',
        margin: '0 auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30%',
        '@media screen and (max-width: 1130px)': {
          width: '42%',
        },
        '@media screen and (max-width: 767px)': {
          width: '55%',
        },
        '@media screen and (max-width: 550px)': {
          width: '75%',
        }
      }}>
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{
            fontSize: '32px',
            width: '60%',
            marginBottom: '15px',
            '@media screen and (max-width: 767px)': {
              width: '80%',
              fontSize: '36px',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{
            fontSize: '28px',
            width: '75%',
            marginBottom: '-5px',
            '@media screen and (max-width: 767px)': {
              width: '95%',
              fontSize: '32px',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{
            fontSize: '28px',
            width: '40%',
            '@media screen and (max-width: 767px)': {
              width: '55%',
              fontSize: '32px',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{
            fontSize: '15px',
            width: '10%',
            alignSelf: 'flex-start',
            marginBottom: '8px',
          }}
        />
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            height: '40px',
            width: '100%',
            borderRadius: '12px',
            marginBottom: '20px',
          }}
        />
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            height: '40px',
            width: '100%',
            borderRadius: '12px',
            marginBottom: '25px',
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{
            width: '50%',
            fontSize: '24px',
          }}
        />
      </Stack>
    </Grid>
  );
};

export default ForgotPasswordSkeleton;
