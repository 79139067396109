import { ICreateMarathon } from "features/createMarathon/types";
import { useFieldArray, useFormContext } from "react-hook-form";

export const useCreateDistance = () => {
  const { control } = useFormContext<ICreateMarathon>();

  const {
    append: appendDistance,
    remove: removeDistance,
    fields: fieldsDistance,
  } = useFieldArray({ control, name: "distances" });

  return {
    appendDistance,
    removeDistance,
    fieldsDistance,
  };
};
