import { Box, Button, Divider, Tab, Tabs, Typography } from "@mui/material";
import { useMarathonTabForm } from "features/createMarathon/model/MarathonTabs/useMarathonTabForm";
import { useEffect, useState } from "react";
import style from "./MarathonContent.module.scss";

import ruImg from "../../../../assets/images/header/russianFlag.svg";
import enImg from "../../../../assets/images/header/britishFlag.svg";
import kgImg from "../../../../assets/images/header/kyrgyzFlag.svg";
import { KgTab } from "../LanguageTabs/KgTab";
import { RuTab } from "../LanguageTabs/RuTab";
import { EnTab } from "../LanguageTabs/EnTab";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: "16px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabsHed({ img, name }: { name: string; img: string }) {
  return (
    <Box sx={{ width: "100%", margin: 0 }} className="tabshead">
      <img src={img} alt="" />
      {name}
    </Box>
  );
}
export const MarathonContent = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [isDragOpen, setIsDragOpen] = useState(false);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const { data, errors } = useMarathonTabForm();
  // @ts-ignore
  const fileReader = new FileReader();

  function getIndexError(index: number, lngIndex: number) {
    return (
      !!errors.dateEvent?.message ||
      !!errors?.translations?.[index] ||
      !!errors.publicOffer?.[index]
    );
  }

  // @ts-ignore
  if (data.documents && data.documents[0] && data.documents[0][0]) {
    // @ts-ignore
    // console.log(data.documents[0][0]);
    // @ts-ignore
    const url = fileReader.readAsDataURL(data.documents[0][0]);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "16px",
            mt: "32px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              fontFamily: "Raleway",
            }}
          >
            Основная информация
          </Typography>
          <Divider sx={{ flex: "1", background: "#ECF2F7" }} />
        </Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<TabsHed img={ruImg} name="Русский" />}
            {...a11yProps(0)}
          />
          <Tab
            label={<TabsHed img={enImg} name="English" />}
            {...a11yProps(1)}
          />
          <Tab
            sx={{ background: getIndexError(2, 3) ? "rgba(255,0,0,0.1)" : "" }}
            label={<TabsHed img={kgImg} name="Кыргызча" />}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <RuTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EnTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <KgTab />
      </CustomTabPanel>
      <Box>
        <div className={style.box_bottom}>
          <div>
            <button
              className={style.btn_back}
              // onClick={() =>
              //   navigate(createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH))
              // }
              type="button"
            >
              Отмена
            </button>
            <button
              className={style.btn_next}
              type="button"
              onClick={() => {
                if (value !== 2) {
                  handleChange("", value + 1);
                  return;
                }
                navigate("/new/step-2");
              }}
            >
              Далее
            </button>
          </div>
        </div>
      </Box>
    </Box>
  );
};
