import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const ResetPasswordSkeleton: React.FC = (): JSX.Element => {
  return (
    <Grid item sx={{
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      }
    }}>
      <Skeleton animation="pulse" variant="rectangular" sx={{
        width: '50%',
        minHeight: '91.9vh',
        '@media screen and (max-width: 767px)': {
          flexDirection: 'column',
          minHeight: '35vh',
          width: '100%'
        }
      }}/>
      <Stack sx={{
        width: '45%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 1130px)': {
          width: '40%',
        },
        '@media screen and (max-width: 767px)': {
          height: '50vh',
          width: '60%',
        },
        '@media screen and (max-width: 560px)': {
          width: '80%',
        }
      }}>
        <Skeleton
          animation="pulse"
          variant="circular"
          sx={{
            width: '65px',
            height: '64px',
            fontSize: '28px',
            marginBottom: '30px',
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          height={40}
          sx={{
            width: '40%',
            fontSize: '28px',
            marginBottom: '20px',
            '@media screen and (max-width: 767px)': {
              width: '60%',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          height={30}
          sx={{
            width: '50%',
            fontSize: '28px',
            '@media screen and (max-width: 767px)': {
              width: '70%',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          height={30}
          sx={{
            width: '60%',
            fontSize: '28px',
            marginBottom: '20px',
            '@media screen and (max-width: 767px)': {
              width: '80%',
            }
          }}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          height={30}
          sx={{
            width: '45%',
            marginBottom: '20px',
          }}
        />
      </Stack>
    </Grid>
  );
};

export default ResetPasswordSkeleton;
