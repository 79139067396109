import React from 'react';
import styles from './PageNotFound.module.scss';
import {useTranslation} from "react-i18next";

const PageNotFound = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.pageNotFound}>
      <div className={styles.top}>
        <h1 className={styles.errorCode}>404</h1>
        <h3>{t('pageNotFound')}</h3>
      </div>
      <div className={styles.pageNotFoundContainer}>
        <div className={styles.ghostCopy}>
          <div className={styles.one}></div>
          <div className={styles.two}></div>
          <div className={styles.three}></div>
          <div className={styles.four}></div>
        </div>
        <div className={styles.ghost}>
          <div className={styles.face}>
            <div className={styles.eye}></div>
            <div className={styles.eyeRight}></div>
            <div className={styles.mouth}></div>
          </div>
        </div>
        <div className={styles.shadow}></div>
      </div>
    </div>
  );
};

export default PageNotFound;
