import { configureStore } from "@reduxjs/toolkit";
import userReducer from "entities/user/model/slice";
import personalCabinetSlice from "entities/viewer/model/slice";
import marathonReducer from "entities/admin/model/slice";
import { rootApi } from "app/rootApi";

const store = configureStore({
  reducer: {
    users: userReducer,
    personalCabinet: personalCabinetSlice,
    marathon: marathonReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
