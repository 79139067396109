import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';
import {toast} from "react-toastify";

export const getApplicationsByMarathonId = createAsyncThunk(
  'admin/getApplicationsByMarathonId', async (applicationData: any, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.getApplicationsByMarathonId(applicationData);
      return response.data;
    } catch (error) {
      toast.error('Ой, что-то пошло не так, попробуйте обновить страницу', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
    }
  }
)
