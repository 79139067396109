import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";

export const getMarathonById = createAsyncThunk(
  "admin/getMarathonById",
  async (id: number) => {
    try {
      const { data } = await typedAdminAPI.getMarathonById(id);
      return data;
    } catch (error) {
      console.log("Error while getting list of Marathons", error);
    }
  }
);
