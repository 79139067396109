import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import downloadIcon from "assets/images/admin/download.svg";
import { downloadPromocodesExcelFile } from "shared/lib/downloadPromocodesExcel";

type Props = {
  voucher: any;
  open: boolean;
  handleClose: any;
  anchorEl: any;
};

const SubVoucherMenu: React.FC<Props> = (props) => {
  const {
    voucher,
    open,
    handleClose,
    anchorEl,
  } = props;

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
        style={{ borderRadius: "10px" }}
        onClose={handleClose}
      >         
        <MenuItem
          onClick={() => {
            handleClose();
            downloadPromocodesExcelFile(voucher.id);
          }}
          sx={{
            borderTop: "1px solid #ECF2F7",
            display: "flex",
            padding: "10px",
            fontFamily: "Raleway",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            borderRadius: "7px",
            color: "#3E444A",
          }}
        >
          <ListItemIcon>
            <img src={downloadIcon} alt="Скачать" />
          </ListItemIcon>
          <span style={{ marginRight: "auto" }}>Скачать</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SubVoucherMenu;
