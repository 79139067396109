import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ACTIVE_MARATHON_PARTICIPANTS_PATH, GENERAL_MARATHON_RESULTS} from "pages/paths";
import {FlagImg, HomePage, Users} from "assets/icons";
import styles from './DrawerNavItems.module.scss';

type DrawerNavItemsProp = {
  handleDrawerToggle: () => void;
}

const DrawerNavItems: React.FC<DrawerNavItemsProp> = ({handleDrawerToggle}): JSX.Element => {
  const {t} = useTranslation();

  const drawerNavItems = [
    {icon: <HomePage/>, label: t('header.main'), path: ''},
    {icon: <Users/>, label: t('header.listOfParticipants'), path: ACTIVE_MARATHON_PARTICIPANTS_PATH},
    {icon: <FlagImg/>, label: t('header.marathonResults'), path: GENERAL_MARATHON_RESULTS},
  ];

  return (
    <ul className={styles.drawerNavItemsContainer}>
      {drawerNavItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.path === '' ? (
            <li>
              {item.icon}
              <a
                onClick={handleDrawerToggle}
                href="https://runthesilkroad.com/"
                rel="noreferrer"
                style={{textAlign: 'left'}}
                target="_blank">
                {item.label}
              </a>
            </li>
          ) : (
            <li onClick={handleDrawerToggle}>
              {item.icon}
              <Link to={item.path} style={{textAlign: 'left'}}>
                {item.label}
              </Link>
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default DrawerNavItems;
