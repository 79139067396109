import { ICreateMarathon } from "features/createMarathon/types";
import { useFieldArray, useFormContext } from "react-hook-form";

export const useCreateAges = ({ indexDistance }: { indexDistance: number }) => {
  const { control } = useFormContext<ICreateMarathon>();

  const {
    append: appendAge,
    remove: removeAge,
    fields: fieldsAges,
  } = useFieldArray({
    control,
    name: `distances.${indexDistance}.distanceAges`,
  });
  return {
    appendAge,
    removeAge,
    fieldsAges,
  };
};
