import axios from "axios";
import { baseURL } from "./baseURL";
import History from "../lib/history";
import store from "../config/configureStore";
import { userLogout } from "../../entities/user/model/slice";

const axiosApi = axios.create({ baseURL });

axiosApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "AccessToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const accessToken = localStorage.getItem("AccessToken");
        const refreshToken = localStorage.getItem("RefreshToken");
        const response = await axiosApi.post("/auth/refresh", {
          accessToken,
          refreshToken,
        });
        localStorage.setItem("AccessToken", response.data.accessToken);
        localStorage.setItem("RefreshToken", response.data.refreshToken);
        return axiosApi.request(originalRequest);
      } catch (error) {
        const responseStatusCode = error.response.data.status === 400;
        const responseMessage = error.response.data.title === "Invalid token";

        if (responseStatusCode && responseMessage) {
          store.dispatch(userLogout());
          localStorage.removeItem("AccessToken");
          localStorage.removeItem("RefreshToken");
          localStorage.removeItem("role");
          localStorage.removeItem("email");
          localStorage.removeItem("userName");
          History.navigate("user/login");
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosApi;
