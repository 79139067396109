import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";

export const getUserInfoByStarterKitId = createAsyncThunk(
  "admin/userInfo",
  async (info: any, { rejectWithValue }) => {
    try {
      const { data } = await typedAdminAPI.getUserInfoBySearchTerm(
        info.marathonId,
        info.searchTerm
      );
      return data;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);
