export const checkCurrentLanguage = () => {
  const language = localStorage.getItem('i18nextLng');

  switch (language) {
    case 'ru':
      return 'ru-ru';
    case 'en':
      return 'en-us';
    case 'kg':
      return 'ky-kg';
    default :
      return 'ru-ru';
  }
};
