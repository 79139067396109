import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from "../api/typedApi";

export const getMarathonResultsBySearch = createAsyncThunk('admin/getMarathonResultsBySearch' ,
  async (marathonData: {marathonId: number, participantSearch: string, gender?: string, chosenDistanceAge?: string, distance?:string}, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.getMarathonResultsBySearch(marathonData);
      return response.data;
    } catch (error) {
      return rejectWithValue('Error');
    }
  });
