import React from 'react';
import { useTranslation } from 'react-i18next';
import infoCircleImg from '../../../assets/images/header/info-circle.svg'
import './HeaderInsuranceBanner.scss';

const HeaderInsuranceBanner = () => {
  const { t } = useTranslation();

  return (
    <div className='toolbar'>
      <div className='icon'>
        <img src={infoCircleImg} alt='Внимание' />
      </div>
      <div className='text'>
        <p className='info'>{t('header.insuranceBannerLine1')}</p>
        <p className='info'>{t('header.insuranceBannerLine2')}</p>
      </div>
    </div>
  );
};

export default HeaderInsuranceBanner;
