import { createAsyncThunk } from "@reduxjs/toolkit";
import { userAPI } from "entities/user/model/api/api";
import { userRegisterData, userLoginData } from "entities/user/lib/types";
import { toast } from "react-toastify";

export const registerUser = createAsyncThunk(
  "users/register",
  async (userData: userRegisterData, { rejectWithValue }) => {
    try {
      const response = await userAPI.register(userData);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 400 && error.response.data.title) {
        return rejectWithValue(error.response.data.title);
      } else {
        return error;
        //TODO: Tostify, что то пошло не так...
      }
    }
  }
);

export const userConfirmEmail = createAsyncThunk(
  "users/confirmEmail",
  async (query: any, { rejectWithValue }) => {
    try {
      const response = await userAPI.confirmEmail(query);
      return response.data;
    } catch (error: any) {
      if (
        error.response.data.status >= 400 &&
        error.response.data.status <= 499
      ) {
        return rejectWithValue(error.response.data.title);
      }

      if (error.response.status >= 500 && error.response.status <= 526) {
        return rejectWithValue(null);
      }

      return rejectWithValue(null);
    }
  }
);

export const userLogin = createAsyncThunk(
  "users/userLogin",
  async (userData: userLoginData, { rejectWithValue }) => {
    try {
      const response = await userAPI.login(userData);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 400) {
        return rejectWithValue(error.response.data);
      }

      toast.error("Ой, что-то пошло не так, попробуйте обновить страницу...", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return rejectWithValue(null);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async (userEmail: string, { rejectWithValue }) => {
    try {
      const response = await userAPI.forgotPassword(userEmail);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 400) {
        return rejectWithValue(error.response.data.title);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (queryUserData: string, { rejectWithValue }) => {
    try {
      const response = await userAPI.changePassword(queryUserData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkUserAuth = createAsyncThunk(
  "users/checkUserLogin",
  async (_, { rejectWithValue }) => {
    const accessToken = localStorage.getItem("AccessToken");
    const refreshToken = localStorage.getItem("RefreshToken");
    try {
      const response = await userAPI.refreshToken(accessToken, refreshToken);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userGoogleLogin = createAsyncThunk(
  "users/googleLogin",
  async (googleJWToken: string, { rejectWithValue }) => {
    try {
      const response = await userAPI.googleLogin(googleJWToken);
      return response.data;
    } catch (error) {
      rejectWithValue("Ошибка");
    }
  }
);

export const getActiveMarathons = createAsyncThunk(
  "users/getActiveMarathons",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userAPI.getActiveMarathons();
      return response.data;
    } catch (error) {
      rejectWithValue("Error");
    }
  }
);

export const getActiveMarathonById = createAsyncThunk(
  "users/getActiveMarathonById",
  async (
    marathonData: {
      marathonId: number;
      page: number;
      pageSize: number;
      participantsSearchInfo: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await userAPI.getActiveMarathonById(marathonData);
      return response.data;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);
