import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const getMarathonResultsByAdmin = createAsyncThunk('admin/getMarathonResultsByAdmin',
async (marathonResults: {
  marathonId: number,
  page: number,
  pageSize: number,
  gender: string,
  distance: string,
  distanceAgeId: string,
  participantSearch?: string
}, {rejectWithValue}) => {
  try {
    const response = await typedAdminAPI.getMarathonResultsByAdmin(marathonResults);
    return response.data;
  } catch (error) {
    return rejectWithValue('Error');
  }
});
