import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { periodSettingModalStyles } from "entities/admin/lib/MUIStyles/MUIStyles";
import { usePeriodDistance } from "features/createMarathon/model/Distance/usePeriodDistance";
import { ICreateMarathon, IDistancePrice } from "features/createMarathon/types";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
} from "react-hook-form";
import { UiDatetField } from "shared/ui/UiDatePicker/UiDateField";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import * as yup from "yup";

interface PricePeriodDistanceProps {
  open: boolean;
  closeHandler: () => void;
  indexDistance: number;
  indexD: number;
  saveAndClose: () => void;
  updatePeriodDistance: UseFieldArrayUpdate<
    ICreateMarathon,
    `distances.${number}.distancePrices`
  >;
  appendPeriodDistance: UseFieldArrayAppend<
    ICreateMarathon,
    `distances.${number}.distancePrices`
  >;
  isEdit: boolean;
  fields: FieldArrayWithId<
    ICreateMarathon,
    `distances.${number}.distancePrices`,
    "id"
  >[];
  removePeriodDistance: UseFieldArrayRemove;
}

export const PricePeriodDistance = ({
  open,
  closeHandler,
  indexDistance,
  indexD,
  saveAndClose,
  appendPeriodDistance,
  updatePeriodDistance,
  isEdit = false,
  fields,
}: PricePeriodDistanceProps) => {
  const { watch, setValue } = useFormContext<ICreateMarathon>();
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
    getValues,
  } = useForm({
    values: isEdit
      ? fields[indexD]
      : {
          dateStart: fields.length
            ? dayjs(fields[fields.length - 1].dateEnd)?.add(1, "day")
            : watch().startDateAcceptingApplications,
          dateEnd: undefined,
          price: "",
        },
    resolver: yupResolver(
      yup.object().shape({
        dateStart: yup
          .date()
          .typeError("Введите корректную дату")
          .required("Укажите дату начала"), // Date required
        dateEnd: yup
          .date()
          .typeError("Введите корректную дату")
          .required("Укажите дату окончания"), // Date required
        price: yup
          .number()
          .min(0, "Цена не может быть отрицательной")
          .typeError("Укажите цену периода")
          .required("Укажите цену периода"),
      })
    ),
  });

  return (
    <Modal open={open} onClose={closeHandler}>
      <Box sx={periodSettingModalStyles}>
        <Box sx={{ mb: "24px" }}>
          <Typography>Настроить стоимость по периодам</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "15px" }}>
          <UiDatetField
            disabled={!fields.length}
            placeholder="дд.мм.гг"
            label="От"
            minDate={
              fields.length
                ? dayjs(fields[fields.length - 1].dateEnd)?.add(1, "day")
                : watch().startDateAcceptingApplications
            }
            maxDate={watch().endDateAcceptingApplications}
            control={control}
            name={`dateStart`}
          />
          <UiDatetField
            placeholder="дд.мм.гг"
            label="До"
            control={control}
            name={`dateEnd`}
            minDate={dayjs(getValues("dateStart"))?.add(1, "day")}
            maxDate={watch().endDateAcceptingApplications}
          />
        </Box>
        <Box sx={{ mt: "16px" }}>
          <UiTextField
            error={errors.price?.message}
            placeholder="Впишите стоимость"
            label="Стоимость участия в выбранный период"
            inputProps={{
              ...register(`price`),
            }}
            InputProps={{ inputProps: { min: 1 } }}
            type="number"
          />
        </Box>
        <Divider sx={{ my: "24px" }} />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={handleSubmit((data) => {
              if (isEdit) {
                const res = fields.filter((_, index) => index <= indexD);

                setValue(
                  `distances.${indexDistance}.distancePrices`,
                  res.map((item, index) => {
                    if (index === indexD) {
                      return data;
                    }
                    return item;
                  })
                );
                saveAndClose();
              } else {
                //@ts-ignore
                appendPeriodDistance(data);
                saveAndClose();
              }
              reset();
            })}
          >
            Сохранить
          </Button>
          <Button onClick={closeHandler}>Отмена</Button>
        </Box>
      </Box>
    </Modal>
  );
};
