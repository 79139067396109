import { createSlice } from "@reduxjs/toolkit";
import { getProfileCreateThunk } from "../thunks/getProfileCreateThunk";
import { postChangePasswordCreateThunk } from "../thunks/postChangePasswordCreateThunk";
import { getFilesCreateThunk } from "./../thunks/getFilesCreateThunk";
import { getDowloadFileCreateThunk } from "./../thunks/getDowloadFileCreateThunk";
import { getMarathonsCreateThunk } from "../thunks/getMarathonsCreateThunk";
import { getMarathonCreateThunk } from "../thunks/getMarathonCreateThunk";
import { getStatusesCreateThunk } from "../thunks/getStatusesCreateThunk";
import { getMarathonUserRigistered } from "../thunks/getMarathonUserRigistered";
import { postApplicationPwdCreateThunk } from "../thunks/postApplicationPwdCreateThunk";
import { postApplicationCreateThunk } from "../thunks/postApplicationCreateThunk";
import { postApplicationMoneyCreateThunk } from "../thunks/postApplicationMoneyCreateThunk";
import { getMyApplicationsCreateThunk } from "../thunks/getMyApplicationsCreateThunk";
import { deleteFileCreateThunk } from "../thunks/deleteFileCreateThunk";
import { putProfileCreateThunk } from "../thunks/putProfileCreateThunk";
import { putFileCreateThunk } from "../thunks/putFileCreateThunk";
import { getMyResults } from "../thunks/getMyResults";
import { getMyResultAsPDF } from "../thunks/getMyResultAsPDF";
import { getPublicOfferCreateThunk } from "../thunks/getPublicOffersCreateThunk";
import { postApplicationInsuranceCreateThunk } from "../thunks/postApplicationInsuranceCreateThunk";
import { postApplicationPwdInsurance } from "../thunks/postApplicationPwdInsurance";
import { postApplicationPromoInsurance } from "../thunks/postApplicationPromoInsurance";
import { postSubVoucherCreateThunk } from "../thunks/postSubVoucherCreateThunk";
import { isCorporateClient } from "../thunks/isCorporateClient";
import { getSubVouchersCreateThunk } from "../thunks/getSubVouchersCreateThunk";
import { getVouchersCreateThunk } from "../thunks/getVouchersCreateThunk";
import { getVoucherDistancesCreateThunk } from "../thunks/getVoucherDistancesCreateThunk";

const initialState = {
  voucherDistances: null,
  vouchers: null,
  vouchersLoading: false,
  subVouchersLoading: false,
  subVouchers: null,
  corporateClient: null,
  subVoucherLoading: false,
  language: null,
  offer: null,
  passwordOldError: null,
  profile: null,
  frontPassportPath: null,
  backPassportPath: null,
  insurancePath: null,
  backInsurancePath: null,
  disabilityPath: null,
  backDisabilityPath: null,
  dowloadFile: null,
  isPasswordNotError: false,
  isChangePasswordLoading: false,
  loadDocumentsLoading: false,
  saveUserLoading: false,
  saveUserError: null,
  marathons: null,
  marathon: null,
  isGetMarathonsLoading: false,
  isApplicationCreateMoneyLoading: false,
  isApplicationCreateInsuranceLoading: false,
  isPostApplicationPwdInsuranceLoading: false,
  isPostApplicationPromoInsuranceLoading: false,
  statuses: null,
  marathonUserRegistred: null,
  applicationPwdSuccess: false,
  applicationPwdTextError: null,
  applicationVoucherSuccess: null,
  applicationVoucherTextError: null,
  postApplicationMoneyLink: null,
  postApplicationInsuranceLink: null,
  postApplicationPwdInsuranceLink: null,
  postApplicationPromoInsuranceLink: null,
  myApplications: null,
  deleteFileSuccess: false,
  isPostApplicationCreateThunkLoading: false,
  isPostApplicationPwdLoading: false,
  isGetMyResultsLoading: false,
  myResults: [],
  resultAsPDF: null,
  getMarathonCreateThunkLoading: false,
};


export const personalCabinetSlice = createSlice({
  name: "personalCabinet",
  initialState,
  reducers: {
    clearTextFieldErrors: (state) => {
      state.passwordOldError = null;
    },
    clearUserData: (state) => {
      state.profile = null;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVoucherDistancesCreateThunk.fulfilled, (state, action) => {
        state.voucherDistances = action.payload;
      })
      .addCase(getSubVouchersCreateThunk.fulfilled, (state, action) => {
        state.subVouchers = action.payload;
        state.subVouchersLoading = false;
      })
      .addCase(getSubVouchersCreateThunk.pending, (state) => {
        state.subVouchersLoading = true;
      })
      .addCase(getSubVouchersCreateThunk.rejected, (state) => {
        state.subVouchersLoading = false;
      })
      .addCase(getVouchersCreateThunk.fulfilled, (state, action) => {
        state.vouchers = action.payload;
        state.vouchersLoading = false;
      })
      .addCase(getVouchersCreateThunk.pending, (state, action) => {
        state.vouchersLoading = true;
      })
      .addCase(getVouchersCreateThunk.rejected, (state, action) => {
        state.vouchersLoading = false;
      })
      .addCase(isCorporateClient.fulfilled, (state, action) => {
        state.corporateClient = action.payload;
      })
      .addCase(postSubVoucherCreateThunk.fulfilled, (state) => {
        state.subVoucherLoading = false;
      })
      .addCase(postSubVoucherCreateThunk.pending, (state) => {
        state.subVoucherLoading = true;
      })
      .addCase(getPublicOfferCreateThunk.fulfilled, (state, action) => {
        state.offer = action.payload;
      }) 
      .addCase(getProfileCreateThunk.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(getFilesCreateThunk.fulfilled, (state, action) => {
        state.frontPassportPath = action.payload.frontPassportPath;
        state.backPassportPath = action.payload.backPassportPath;
        state.insurancePath = action.payload.insurancePath;
        state.backInsurancePath = action.payload.backInsurancePath;
        state.disabilityPath = action.payload.disabilityPath;
        state.backDisabilityPath = action.payload.backDisabilityPath;
      })
      .addCase(getDowloadFileCreateThunk.fulfilled, (state, action) => {
        state.dowloadFile = action.payload;
      })
      .addCase(postChangePasswordCreateThunk.pending, (state) => {
        state.isChangePasswordLoading = true;
      })
      .addCase(postChangePasswordCreateThunk.fulfilled, (state, _) => {
        state.isPasswordNotError = true;
        state.isChangePasswordLoading = false;
      })
      .addCase(postChangePasswordCreateThunk.rejected, (state, action) => {
        state.passwordOldError = action.payload;
        state.isChangePasswordLoading = false;
      })
      .addCase(putFileCreateThunk.pending, (state) => {
        state.loadDocumentsLoading = true;
      })
      .addCase(putFileCreateThunk.fulfilled, (state) => {
        state.loadDocumentsLoading = false;
      })
      .addCase(putFileCreateThunk.rejected, (state) => {
        state.loadDocumentsLoading = false;
      })
      .addCase(deleteFileCreateThunk.fulfilled, (state, _) => {
        state.deleteFileSuccess = !state.deleteFileSuccess;
      })
      .addCase(getMyApplicationsCreateThunk.fulfilled, (state, action) => {
        state.myApplications = action.payload;
      })

      .addCase(postApplicationPwdCreateThunk.pending, (state, _) => {
        state.isPostApplicationPwdLoading = true;
      })
      .addCase(postApplicationPwdCreateThunk.fulfilled, (state, _) => {
        state.applicationPwdSuccess = true;
        state.applicationPwdTextError = null;
        state.isPostApplicationPwdLoading = false;
      })
      .addCase(postApplicationPwdCreateThunk.rejected, (state, action) => {
        state.applicationPwdTextError = action.payload;
        state.isPostApplicationPwdLoading = false;
      })

      .addCase(postApplicationPwdInsurance.pending, (state, _) => {
        state.isPostApplicationPwdInsuranceLoading = true;
      })
      .addCase(postApplicationPwdInsurance.fulfilled, (state, action) => {
        state.postApplicationPwdInsuranceLink = action.payload;
        state.isPostApplicationPwdInsuranceLoading = false;
      })
      .addCase(postApplicationPwdInsurance.rejected, (state, action) => {
        state.applicationPwdTextError = action.payload;
        state.isPostApplicationPwdInsuranceLoading = false;
      })

      .addCase(postApplicationCreateThunk.pending, (state) => {
        state.isPostApplicationCreateThunkLoading = true;
      })
      .addCase(postApplicationCreateThunk.fulfilled, (state) => {
        state.applicationVoucherSuccess = true;
        state.applicationVoucherTextError = null;
        state.isPostApplicationCreateThunkLoading = false;
      })
      .addCase(postApplicationCreateThunk.rejected, (state, action) => {
        state.applicationVoucherTextError = action.payload;
        state.isPostApplicationCreateThunkLoading = false;
      })

      .addCase(postApplicationPromoInsurance.pending, (state) => {
        state.isPostApplicationPromoInsuranceLoading = true;
      })
      .addCase(postApplicationPromoInsurance.fulfilled, (state, action) => {
        state.postApplicationPromoInsuranceLink = action.payload;
        state.isPostApplicationPromoInsuranceLoading = false;
      })
      .addCase(postApplicationPromoInsurance.rejected, (state, action) => {
        state.applicationVoucherTextError = action.payload;
        state.isPostApplicationPromoInsuranceLoading = false;
      })

      .addCase(postApplicationMoneyCreateThunk.pending, (state) => {
        state.isApplicationCreateMoneyLoading = true;
      })
      .addCase(postApplicationMoneyCreateThunk.fulfilled, (state, action) => {
        state.postApplicationMoneyLink = action.payload;
        state.isApplicationCreateMoneyLoading = false;
      })
      .addCase(postApplicationMoneyCreateThunk.rejected, (state) => {
        state.isApplicationCreateMoneyLoading = false;
      })
      .addCase(postApplicationInsuranceCreateThunk.pending, (state) => {
        state.isApplicationCreateInsuranceLoading = true;
      })
      .addCase(
        postApplicationInsuranceCreateThunk.fulfilled,
        (state, action) => {
          state.postApplicationInsuranceLink = action.payload;
          state.isApplicationCreateInsuranceLoading = false;
        }
      )
      .addCase(postApplicationInsuranceCreateThunk.rejected, (state) => {
        state.isApplicationCreateInsuranceLoading = false;
      })
      .addCase(getMarathonsCreateThunk.pending, (state) => {
        state.isGetMarathonsLoading = true;
      })
      .addCase(getMarathonsCreateThunk.fulfilled, (state, action) => {
        state.marathons = action.payload;
        state.isGetMarathonsLoading = false;
      })
      .addCase(getMarathonsCreateThunk.rejected, (state) => {
        state.isGetMarathonsLoading = false;
      })
      .addCase(getMarathonCreateThunk.pending, (state) => {
        state.getMarathonCreateThunkLoading = true;
      })
      .addCase(getMarathonCreateThunk.fulfilled, (state, action) => {
        state.getMarathonCreateThunkLoading = false;
        state.marathon = action.payload;
      })
      .addCase(getMarathonCreateThunk.rejected, (state) => {
        state.getMarathonCreateThunkLoading = false;
      })
      .addCase(getStatusesCreateThunk.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(getMarathonUserRigistered.fulfilled, (state, action) => {
        state.marathonUserRegistred = action.payload;
      })
      .addCase(putProfileCreateThunk.pending, (state) => {
        state.saveUserLoading = true;
      })
      .addCase(putProfileCreateThunk.fulfilled, (state) => {
        state.saveUserLoading = false;
      })
      .addCase(putProfileCreateThunk.rejected, (state, action) => {
        state.saveUserLoading = false;
        state.saveUserError = action.payload;
      })
      .addCase(getMyResults.pending, (state) => {
        state.isGetMyResultsLoading = true;
      })
      .addCase(getMyResults.fulfilled, (state, action) => {
        state.myResults = action.payload.query;
        state.isGetMyResultsLoading = false;
      })
      .addCase(getMyResults.rejected, (state) => {
        state.isGetMyResultsLoading = false;
      })
      .addCase(getMyResultAsPDF.fulfilled, (state, action) => {
        state.resultAsPDF = action.payload;
      });
  },
});

export const { clearTextFieldErrors, clearUserData, setLanguage } =
  personalCabinetSlice.actions;

export default personalCabinetSlice.reducer;
