import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import style from "./ui-check-box.module.scss";

interface FormInputProps {
  name: any;
  control: any;
  label: any;
}

export const UiCheckBox: React.FC<FormInputProps> = ({
  name,
  control,
  label,
}) => {
  return (
    <FormControl component="fieldset">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Controller
          name={name}
          control={control}
          defaultValue={false}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => <Checkbox onChange={onChange} value={value} />}
        />
        <label className={style.label}>{label}</label>
      </Box>
    </FormControl>
  );
};
