import {useNavigate} from "react-router-dom";
import history from '../history';

const CustomNavigate = () => {
  history.navigate = useNavigate();

  return null;
};

export default CustomNavigate;
