import { baseURL } from "shared/api/baseURL";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { checkCurrentLanguage } from "shared/lib/checkCurrentLanguage";

export const rootApi = createApi({
  reducerPath: "intance",

  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json;charset=UTF-8");
      const AccessToken = localStorage.getItem("AccessToken");
      headers.set("Authorization", `Bearer ${AccessToken}`);
      headers.set("Accept-Language", checkCurrentLanguage());
      return headers;
    },
  }),
  tagTypes: ["SubVoucher", "CorporateClient"],
  endpoints: (builder) => ({}),
});
