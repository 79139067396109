import { Box, Button, IconButton } from "@mui/material";
import { DeleteIcon } from "assets/icons";
import { ICreateMarathon } from "features/createMarathon/types";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  useFormContext,
} from "react-hook-form";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";

interface DistanceAgeProps {
  fieldsAges: FieldArrayWithId<
    ICreateMarathon,
    `distances.${number}.distanceAges`,
    "id"
  >[];
  index: number;
  removeAge: (index?: number | number[] | undefined) => void;
  type: "male" | "female";
  appendAge: UseFieldArrayAppend<
    ICreateMarathon,
    `distances.${number}.distanceAges`
  >;
}

export const DistanceAge = ({
  fieldsAges,
  index,
  removeAge,
  appendAge,
  type,
}: DistanceAgeProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const genderStatus = type === "male";
  return (
    <Box>
      {fieldsAges
        .filter((item) => item.gender === genderStatus)
        .map((age, indexAge, gender) => {
          const currentIndex = fieldsAges.findIndex(
            (itemAge) => itemAge.id === age.id
          );
          return (
            <Box
              key={currentIndex}
              sx={{ display: "flex", gap: "15px", alignItems: "start" }}
            >
              <UiTextField
                error={
                  errors?.distances?.[index]?.distanceAges?.[currentIndex]
                    ?.ageFrom?.message
                }
                label={`Возрастная группа для ${
                  genderStatus ? "мужчин" : "женщин"
                }`}
                inputProps={{
                  ...register(
                    `distances.${index}.distanceAges.${currentIndex}.ageFrom`
                  ),
                  // defaultValue: gender[currentIndex - 1]?.ageTo || "",
                }}
                type="number"
              />
              <UiTextField
                error={
                  errors?.distances?.[index]?.distanceAges?.[currentIndex]
                    ?.ageTo?.message
                }
                inputProps={{
                  ...register(
                    `distances.${index}.distanceAges.${currentIndex}.ageTo`
                  ),
                }}
                type="number"
              />
              {gender.length > 1 ? (
                <IconButton onClick={() => removeAge(currentIndex)}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                ""
              )}
            </Box>
          );
        })}
      <Button
        sx={{
          ...createCategoryBtnStyles,
          marginTop: "25px",
          marginBottom: "22px",
        }}
        onClick={() => {
          // @ts-ignore
          appendAge({
            gender: genderStatus,
          });
        }}
      >
        Добавить категорию
      </Button>
    </Box>
  );
};

export const createCategoryBtnStyles = {
  color: "#0090D1",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
};
