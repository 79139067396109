import {
  CHANGE_PASSWORD_PATH,
  MY_DOCUMENTS_PATH,
  MY_MARATHON_PATH,
  MY_RESULTS_PATH,
  PASSPORT_PATH,
  PERSONAL_CABINET_PATH,
  PROFILE_PATH,
  USER_LOGIN_PATH,
} from "pages/paths";
import {CupImg, FlagImg, FolderImg, HeaderArrowDown, HeaderArrowUp, KeyImg, LogoutImg, UserImg} from "assets/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "shared/lib/hooks/hooks";

import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {clearUserData} from "entities/viewer/model/slice";
import { createPaths } from "shared/lib/createPaths";
import styles from "./DrawerDropDownMenu.module.scss";
import {useTranslation} from "react-i18next";
import { userLogout } from "entities/user/model/slice";

type DrawerDropDownMenuProps = {
  nestedMenuHandleClick: () => void;
  nestedMenuOpen: boolean;
  handleDrawerToggle: () => void;
};

const DrawerDropDownMenu: React.FC<DrawerDropDownMenuProps> = (
  props
): JSX.Element => {
  const { nestedMenuHandleClick, nestedMenuOpen, handleDrawerToggle } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const userName = localStorage.getItem("userName");
  // @ts-ignore
  const name = useAppSelector((state) => state.personalCabinet.profile?.name);

  const clearLocalStorage = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('userName');
    localStorage.removeItem('create_marathon_step_2');
    dispatch(userLogout());
    dispatch(clearUserData());
    navigate(USER_LOGIN_PATH);
  };

  const userDropDownMenu = [
    {
      label: t('userDropDownMenu.profile'),
      path: createPaths(PERSONAL_CABINET_PATH, PROFILE_PATH),
      dropDownImage: <UserImg />,
    },
    {
      label: t('userDropDownMenu.myDocuments'),
      path: createPaths(PERSONAL_CABINET_PATH, MY_DOCUMENTS_PATH, PASSPORT_PATH),
      dropDownImage: <FolderImg />,
    },
    {
      label: t('userDropDownMenu.myMarathons'),
      path: createPaths(PERSONAL_CABINET_PATH, MY_MARATHON_PATH),
      dropDownImage: <CupImg />,
    },
    {
      label: t('userDropDownMenu.myResults'),
      path: createPaths(PERSONAL_CABINET_PATH, MY_RESULTS_PATH),
      dropDownImage: <FlagImg />,
    },
    {
      label: t('userDropDownMenu.changePassword'),
      path: createPaths(PERSONAL_CABINET_PATH, CHANGE_PASSWORD_PATH),
      dropDownImage: <KeyImg />,
    },
    { label: t('userDropDownMenu.logout'), path: USER_LOGIN_PATH, dropDownImage: <LogoutImg /> },
  ];

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        padding: "0 0 0 7px",
        marginRight: "20px",
      }}
      component="nav"
    >
      <ListItemButton onClick={nestedMenuHandleClick}>
        <ListItemIcon>
          <Avatar />
        </ListItemIcon>
        <ListItemText
          primary={name || userName || t('userDropDownMenu.guest')}
          sx={{ display: "flex", flexGrow: 0 }}
        />
        {nestedMenuOpen ? (
          <Grid
            sx={{
              alignSelf: "flex-start",
              marginLeft: "10px",
              marginTop: "8px",
            }}
          >
            <HeaderArrowUp />
          </Grid>
        ) : (
          <Grid
            sx={{
              alignSelf: "flex-start",
              marginLeft: "10px",
              marginTop: "8px",
            }}
          >
            <HeaderArrowDown />
          </Grid>
        )}
      </ListItemButton>
      <Collapse in={nestedMenuOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            "& .MuiListItemButton-root": { padding: 0 },
            "& .MuiListItemButton-root:hover": { bgcolor: "#F0F8FF" },
          }}
        >
          {userDropDownMenu.map((item, index) => (
            <NavLink
              key={index}
              onClick={handleDrawerToggle}
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? styles.userDropDownMenuLinkActive
                  : styles.userDropDownMenuLink
              }
            >
              <ListItemButton sx={{ py: "0", pl: "8px" }}>
                <MenuItem
                  onClick={(e: any) => {
                    e.target.innerText === t('userDropDownMenu.logout') && clearLocalStorage();
                  }}
                  sx={{
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "#F0F8FF",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {item.dropDownImage}
                  <span className={styles.userDropDownMenuLabel}>
                    {item.label}
                  </span>
                </MenuItem>
              </ListItemButton>
            </NavLink>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default DrawerDropDownMenu;
