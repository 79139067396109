import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const getMarathonByIdForAdmin = createAsyncThunk(
  'admin/getMarathonByIdForAdmin', async (marathonId: number) => {
    try {
      const response = await typedAdminAPI.getMarathonByIdForAdmin(marathonId);
      return response.data;
    } catch (error) {
      console.log('Error');
    }
  }
)
