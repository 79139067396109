import React from 'react';
import {Button, CircularProgress, ButtonProps} from "@mui/material";
import styles from './ButtonWithProgress.module.scss';

interface ButtonWithProgressProps extends ButtonProps {
  children: React.ReactNode;
  loading: boolean;
}

const ButtonWithProgress: React.FC<ButtonWithProgressProps> = ({children, loading, ...props}): JSX.Element => {
  return (
    <Button
      className={styles.wrapper}
      style={{backgroundColor: loading ? '#cccccc' : '#0090D1'}}
      {...props}
    >
      {children}
      {loading && <CircularProgress size={20} className={styles.buttonProgress}/>}
    </Button>
  );
};

export default ButtonWithProgress;
