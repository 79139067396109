import { Box } from "@mui/material";
import { MarathonForm } from "features/createMarathon";

const CreateMarathonNew = () => {
  return (
    <Box
      className="container-adminPanel"
      sx={{ maxWidth: "600px", width: "100%", margin: "0 auto", mt: "2rem" }}
    >
      <MarathonForm />
    </Box>
  );
};

export default CreateMarathonNew;
