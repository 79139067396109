import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import { toast } from "react-toastify";
import { IPagination, IVoucherFilter } from "entities/admin/lib/types";

export const getVouchers = createAsyncThunk(
  "admin/getVouchers",
  async (
    {
      pagination,
      vouchersFilters,
      sorting,
    }: {
      pagination?: IPagination;
      vouchersFilters?: IVoucherFilter;
      sorting?: any[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await typedAdminAPI.getVouchers(
        pagination,
        vouchersFilters,
        sorting
      );
      return data;
    } catch (error: any) {
      toast.error("Ой, что-то пошло не так, попробуйте обновить страницу", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return rejectWithValue(
        "Ой, что-то пошло не так, попробуйте обновить страницу"
      );
    }
  }
);
