import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';
import {toast} from "react-toastify";

export const deleteUserByOwner = createAsyncThunk(
  'deleteUserByOwner/admin' , async (userId: string, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.deleteUserByOwner(userId);
      return response.data;
    } catch (error: any) {
      if (error.response.data.status === 404) {
        toast.error('Пользователь не найден', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Пользователь не найден');
      }

      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить странциу');
    }
  }
)
