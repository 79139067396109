import {
  ACTIVE_MARATHON_PARTICIPANTS_PATH,
  CHANGE_PASSWORD_PATH,
  MY_DOCUMENTS_PATH,
  MY_MARATHON_PATH,
  MY_RESULTS_PATH,
  PASSPORT_PATH,
  PERSONAL_CABINET_PATH,
  PROFILE_PATH,
  USER_LOGIN_PATH,
} from "pages/paths";
import {
  CupImg,
  FlagImg,
  FolderImg,
  HeaderArrowDown,
  HeaderArrowUp,
  KeyImg,
  LogoutImg,
  OriginalUsers,
  UserImg,
} from "assets/icons";
import { Grid, Menu, PopoverOrigin, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import {
  userDropDownMenuItem,
  userDropDownMenuPaperProps,
  userDropDownMenuTransformOrigin,
} from "widget/MUIStyles";

import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { clearUserData } from "entities/viewer/model/slice";
import { createPaths } from "shared/lib/createPaths";
import styles from "./UserDropDownMenu.module.scss";
import { useAppSelector } from "shared/lib/hooks/hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { userLogout } from "entities/user/model/slice";

const UserDropDownMenu: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userName = localStorage.getItem("userName");
  // @ts-ignore
  const name = useAppSelector((state) => state.personalCabinet.profile?.name);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const userDropDownMenu = [
    {
      label: t("userDropDownMenu.profile"),
      path: createPaths(PERSONAL_CABINET_PATH, PROFILE_PATH),
      dropDownImage: <UserImg />,
    },
    {
      label: t("userDropDownMenu.myDocuments"),
      path: createPaths(
        PERSONAL_CABINET_PATH,
        MY_DOCUMENTS_PATH,
        PASSPORT_PATH
      ),
      dropDownImage: <FolderImg />,
    },
    {
      label: t("userDropDownMenu.myMarathons"),
      path: createPaths(PERSONAL_CABINET_PATH, MY_MARATHON_PATH),
      dropDownImage: <CupImg />,
    },
    {
      label: t("userDropDownMenu.myResults"),
      path: createPaths(PERSONAL_CABINET_PATH, MY_RESULTS_PATH),
      dropDownImage: <FlagImg />,
    },
    {
      label: t("activeMarathonListOfParticipants.mainTitle"),
      path: createPaths(ACTIVE_MARATHON_PARTICIPANTS_PATH),
      dropDownImage: <OriginalUsers />,
    },
    {
      label: t("userDropDownMenu.changePassword"),
      path: createPaths(PERSONAL_CABINET_PATH, CHANGE_PASSWORD_PATH),
      dropDownImage: <KeyImg />,
    },
    {
      label: t("userDropDownMenu.logout"),
      path: USER_LOGIN_PATH,
      dropDownImage: <LogoutImg />,
    },
  ];

  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearLocalStorage = () => {
    dispatch(userLogout());
    dispatch(clearUserData());
    navigate(USER_LOGIN_PATH);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 1100px)": { display: "none" },
      }}
    >
      <Typography
        className={styles.userName}
        sx={{
          mx: "5px",
          maxWidth: "250px",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {(name?.length > 29 ? name?.slice(0, 30) + "…" : name) ||
          (userName && userName.length > 29
            ? userName?.slice(0, 30) + "…"
            : userName) ||
          t("userDropDownMenu.guest")}
      </Typography>
      <Menu
        style={{ borderRadius: "10px" }}
        id="basic-menu"
        sx={{ mt: "25px", ml: "130px" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={userDropDownMenuTransformOrigin as PopoverOrigin}
        PaperProps={userDropDownMenuPaperProps}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {userDropDownMenu.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              isActive
                ? styles.userDropDownMenuLinkActive
                : styles.userDropDownMenuLink
            }
          >
            <MenuItem
              onClick={(e) => {
                (e.target as HTMLElement).innerText ===
                  t("userDropDownMenu.logout") && clearLocalStorage();
                handleClose();
              }}
              sx={userDropDownMenuItem}
            >
              {item.dropDownImage}
              <span className={styles.userDropDownMenuLabel}>{item.label}</span>
            </MenuItem>
          </NavLink>
        ))}
      </Menu>
      <Grid
        sx={{ position: "relative", top: "2px", cursor: "pointer" }}
        onClick={handleClick}
      >
        {open ? <HeaderArrowUp /> : <HeaderArrowDown />}
      </Grid>
    </Grid>
  );
};

export default UserDropDownMenu;
