import React from 'react';
import {useAppSelector} from "shared/lib/hooks/hooks";
import PageNotFound from "pages/PageNotFound/PageNotFound";

type Props = {
  allowedRoles: string[];
  children: React.ReactElement;
};

const RequireAuth: React.FC<Props> = ({allowedRoles, children}): JSX.Element => {
  const userRole = useAppSelector(state => state.users.userRole);
  const isAuth = useAppSelector(state => state.users.isAuth);

  return (
    allowedRoles.some((role: string) => role === userRole) && isAuth ?
      children : <PageNotFound/>
  );
};

export default RequireAuth;
