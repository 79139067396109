import React, { useEffect } from "react";
import {useAppDispatch, useAppSelector} from 'shared/lib/hooks/hooks';
import {checkUserAuth} from 'entities/user/model/thunks';
import {CircularProgress} from "@mui/material";
import Routing from "pages/index";
import '../app/index.scss'

function App() {
  //TODO: Mobile datepicker <<< 768px (Maybe)
  const dispatch = useAppDispatch();
  const isCheckUserLoading = useAppSelector(state => state.users.isCheckUserLoading);

  useEffect(() => {
    if (localStorage.getItem("AccessToken")) {
      dispatch(checkUserAuth());
    }
  }, [dispatch]);

  if (isCheckUserLoading) {
    return <CircularProgress sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
      fontSize: '50px',
    }}/>
  }

  //Там где slice нужно  вставить сервер url
  //Исправить checkBreadCrumbs
  //Исправить импорты везде и у ороза, добавить курсор поинтер
  //datepicker менять язык в зависимости от языка
  //

  return <Routing/>;
}

export default App;
