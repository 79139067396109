import React from 'react';
import {Link} from "react-router-dom";
import {ACTIVE_MARATHON_PARTICIPANTS_PATH, GENERAL_MARATHON_RESULTS} from "../../../pages/paths";
import Box from "@mui/material/Box";

const linkStyles = {
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  fontFamily: 'Raleway',
  margin: '0 8px',
  fontWeight: '400',
  color: '#68717B',
};

const NavItems = ({t}) => {
  const navItems = [
    {label: t('header.main'), path: ''},
    {label: t('header.listOfParticipants'), path: ACTIVE_MARATHON_PARTICIPANTS_PATH},
    {label: t('header.marathonResults'), path: GENERAL_MARATHON_RESULTS},
  ];

  return (
    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
      <ul style={{display: 'flex', alignItems: 'center'}}>
        {navItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.path === '' ? (
              <li style={linkStyles}>
                <a
                  href="https://runthesilkroad.com/"
                  rel="noreferrer"
                  target="_blank"
                  style={{color: "inherit"}}
                >
                  {item.label}
                </a>
              </li>
            ) : (
              <li style={linkStyles}>
                <Link to={item.path} style={{color: 'inherit'}}>
                  {item.label}
                </Link>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </Box>
  );
};

export default NavItems;
