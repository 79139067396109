import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const postStatusComments = createAsyncThunk(
  "admin/postStatusComments",
  async (commentData : any, {rejectWithValue}) => {
    try {
      const { data } = await adminAPI.postStatusComments(commentData);
      return data
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
