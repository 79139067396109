import {
  ADMIN_PANEL_PATH,
  ALL_USERS_PATH,
  MARATHONS_PATH,
  ROLES,
  STARTER_KIT_PATH,
  USERS_PATH,
  USER_LOGIN_PATH,
  VOUCHERS_PATH
} from 'pages/paths';
import {
  AdminSettings,
  Cup,
  Flag,
  HeaderArrowDown,
  HeaderArrowUp,
  LanguageInterface,
  LogoutImg,
  StarterKit,
  Users,
  Voucher
} from 'assets/icons';
import {NavLink, useNavigate} from "react-router-dom";
import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from 'shared/lib/hooks/hooks';

import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import LanguageDropDown from "shared/ui/LanguageDropDown/LanguageDropDown";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import {clearUserData} from "entities/viewer/model/slice";
import {createPaths} from "shared/lib/createPaths";
import styles from './AdminDrawerDropDownMenu.module.scss';
import {useTranslation} from "react-i18next";
import {userDropDownMenuItem} from "widget/MUIStyles";
import {userLogout} from 'entities/user/model/slice';

type DrawerDropDownMenuProps = {
  nestedMenuHandleClick: () => void;
  nestedMenuOpen: boolean;
  handleDrawerToggle: () => void;
}

const AdminDrawerDropDownMenu: React.FC<DrawerDropDownMenuProps> = (props): JSX.Element => {
  const userRole = useAppSelector(state => state.users.userRole);

  const {nestedMenuHandleClick, nestedMenuOpen} = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const userName = localStorage.getItem('userName');
  // @ts-ignore
  const name = useAppSelector(state => state.personalCabinet.profile?.name);
  const profileData = useAppSelector((state: any) => state.personalCabinet?.profile);
  const {t} = useTranslation()
  const clearLocalStorage = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('userName');
    localStorage.removeItem('create_marathon_step_2');
    dispatch(userLogout());
    dispatch(clearUserData());
    navigate(USER_LOGIN_PATH);
  };

  const ownerDropDownMenu = [
    {label: t('dropDownMenu.users'), path: createPaths(ADMIN_PANEL_PATH, USERS_PATH, ALL_USERS_PATH), dropDownImage: <Users/>},
    {label: t('dropDownMenu.marathons'), path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH), dropDownImage: <Cup/>},
    {label: t('dropDownMenu.vouchers'), path: createPaths(ADMIN_PANEL_PATH, VOUCHERS_PATH), dropDownImage: <Voucher/>},
    {label: t('dropDownMenu.starterPacks'), path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH), dropDownImage: <StarterKit/>},
    {label: t('dropDownMenu.roles'), path: createPaths(ADMIN_PANEL_PATH, ROLES), dropDownImage: <AdminSettings/>},
    {label: t('dropDownMenu.settings'), path: 'settings/mockPath1', dropDownImage: <AdminSettings/>},
    {label: t('dropDownMenu.interfaceLanguage'), path: 'settings/mockPath2', dropDownImage: <LanguageInterface/>},
    {label: t('dropDownMenu.goOut'), path: '/user/login', dropDownImage: <LogoutImg/>},
  ];

  const adminDropDownMenu = [
    {label: t('dropDownMenu.users'), path: createPaths(ADMIN_PANEL_PATH, USERS_PATH, ALL_USERS_PATH), dropDownImage: <Users/>},
    {label: t('dropDownMenu.marathons'), path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH), dropDownImage: <Cup/>},
    {label: t('dropDownMenu.vouchers'), path: createPaths(ADMIN_PANEL_PATH, VOUCHERS_PATH),  dropDownImage: <Voucher/>},
    {label: t('dropDownMenu.starterPacks'), path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH), dropDownImage: <StarterKit/>},
    {label: t('dropDownMenu.settings'), path: 'settings/mockPath1', dropDownImage: <AdminSettings/>},
    {label: t('dropDownMenu.interfaceLanguage'), path: 'settings/mockPath2', dropDownImage: <LanguageInterface/>},
    {label: t('dropDownMenu.goOut'), path: '/user/login', dropDownImage: <LogoutImg/>},
  ];

  const volunteerDropDownMenu = [
    {label: t('dropDownMenu.marathons'), path: createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH), dropDownImage: <Cup/>},
    {label: t('dropDownMenu.starterPacks'), path: createPaths(ADMIN_PANEL_PATH, STARTER_KIT_PATH), dropDownImage: <StarterKit/>},
    {label: t('dropDownMenu.goOut'), path: '/user/login', dropDownImage: <LogoutImg/>},
  ];

  return (
    <List
      sx={{width: '100%', bgcolor: 'background.paper', padding: '0 0 0 7px', marginRight: '20px'}}
      component="nav"
    >
      <ListItemButton onClick={nestedMenuHandleClick}>
        <ListItemIcon>
          <Avatar/>
        </ListItemIcon>
        <ListItemText
          sx={{
            display: 'flex', flexGrow: 0,
            "& .MuiListItemText-primary": {
              wordBreak: "break-all",
            }
        }}
          primary={profileData?.email || name || userName || t('userDropDownMenu.guest')}
        />
        {nestedMenuOpen ? (
            <Grid sx={{alignSelf: 'flex-start', marginLeft: '10px', marginTop: '8px'}}>
              <HeaderArrowUp/>
            </Grid>
          ) :
          <Grid sx={{alignSelf: 'flex-start', marginLeft: '10px', marginTop: '8px'}}>
            <HeaderArrowDown/>
          </Grid>
        }
      </ListItemButton>
      <Collapse in={nestedMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" sx={{'& .MuiListItemButton-root': {padding: 0}, '& .MuiListItemButton-root:hover': {bgcolor: '#F0F8FF'}}}>
          {userRole === 'Volunteer' &&
            volunteerDropDownMenu.map((item, index) => (
                <div key={index}>
                  {item.label !== `${t('dropDownMenu.interfaceLanguage')}` ? (
                    <NavLink
                      to={item.path}
                      className={({isActive}) => isActive ? styles.userDropDownMenuLinkActive : styles.userDropDownMenuLink}>
                      <MenuItem
                        onClick={e => {
                          (e.target as HTMLElement).innerText === `${t('dropDownMenu.goOut')}` && clearLocalStorage();
                          (e.target as HTMLElement).innerText === `${t('dropDownMenu.marathons')}` && props.handleDrawerToggle();
                          (e.target as HTMLElement).innerText === `${t('dropDownMenu.starterPacks')}` && props.handleDrawerToggle();
                        }}
                        sx={userDropDownMenuItem}>
                        {item.dropDownImage}
                        <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                      </MenuItem>
                    </NavLink>
                  ) : (
                    <MenuItem
                      onClick={() => setIsSelectOpen(prevState => !prevState)}
                      sx={userDropDownMenuItem}
                      className={styles.userDropDownMenuLink}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {item.dropDownImage}
                        <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                        <LanguageDropDown isSelectOpen={isSelectOpen}/>
                      </div>
                    </MenuItem>
                  )}
                </div>
              ))}
          {userRole === 'Owner' &&
            ownerDropDownMenu.map((item, index) => (
              <div key={index}>
                {item.label !== `${t('dropDownMenu.interfaceLanguage')}` ? (
                  <NavLink
                    to={item.path}
                    className={({isActive}) => isActive ? styles.userDropDownMenuLinkActive : styles.userDropDownMenuLink}>
                    <MenuItem
                      onClick={e => {
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.goOut')}` && clearLocalStorage();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.goOut')}` && nestedMenuHandleClick();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.vouchers')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.users')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.marathons')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.roles')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.settings')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.starterPacks')}` && props.handleDrawerToggle();
                      }}
                      sx={userDropDownMenuItem}>
                      {item.dropDownImage}
                      <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                    </MenuItem>
                  </NavLink>
                ) : (
                  <MenuItem
                    onClick={() => setIsSelectOpen(prevState => !prevState)}
                    sx={userDropDownMenuItem}
                    className={styles.userDropDownMenuLink}
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {item.dropDownImage}
                      <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                      <LanguageDropDown isSelectOpen={isSelectOpen}/>
                    </div>
                  </MenuItem>
                )}
              </div>
            ))}
          {userRole === 'Admin' &&
            adminDropDownMenu.map((item, index) => (
              <div key={index}>
                {item.label !== `${t('dropDownMenu.interfaceLanguage')}` ? (
                  <NavLink
                    to={item.path}
                    className={({isActive}) => isActive ? styles.userDropDownMenuLinkActive : styles.userDropDownMenuLink}>
                    <MenuItem
                      onClick={e => {
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.goOut')}` && clearLocalStorage();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.goOut')}` && nestedMenuHandleClick();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.vouchers')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.users')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.marathons')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.settings')}` && props.handleDrawerToggle();
                        (e.target as HTMLElement).innerText === `${t('dropDownMenu.starterPacks')}` && props.handleDrawerToggle();
                      }}
                      sx={userDropDownMenuItem}>
                      {item.dropDownImage}
                      <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                    </MenuItem>
                  </NavLink>
                ) : (
                  <MenuItem
                    onClick={() => setIsSelectOpen(prevState => !prevState)}
                    sx={userDropDownMenuItem}
                    className={styles.userDropDownMenuLink}
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {item.dropDownImage}
                      <span className={styles.userDropDownMenuLabel}>{item.label}</span>
                      <LanguageDropDown isSelectOpen={isSelectOpen}/>
                    </div>
                  </MenuItem>
                )}
              </div>
            ))}
        </List>
      </Collapse>
    </List>
  );
};

export default AdminDrawerDropDownMenu;
