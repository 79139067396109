import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from "../api/typedApi";

export const getUserInfoByStartNumber = createAsyncThunk('admin/getUserInfoByStartNumber',
  async (marathonData: {marathonId: number, startNumber: number | string}, {rejectWithValue}) => {
    try {
        const response = await typedAdminAPI.getUserInfoByStartNumber(marathonData);
        return response.data;
    } catch (error) {
      return rejectWithValue('Error');
    }
  });
