import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const getFilesCreateThunk = createAsyncThunk(
    "personalCabinet/files",
    async (_, { rejectedWithValue }) => {
        try {
            const { data } = await personalCabinetAPI.getFiles()
            return data;
          } catch (error) {
            return rejectedWithValue(error.response.data);
          }
    }
)
