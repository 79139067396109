import React from "react";
import styles from "./distance.module.scss";
import Button from "@mui/material/Button";
import editSvg from "assets/images/admin/edit.svg";
import deleteSvg from "assets/images/admin/delete.svg";
import calendarSvg from "assets/images/admin/calendar.svg";
import { FieldArrayWithId } from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";
import dayjs from "dayjs";

interface IDistanceItemProps {
  field: FieldArrayWithId<
    ICreateMarathon,
    `distances.${number}.distancePrices`,
    "id"
  >;
  onClick: () => void;
  remove: () => void;
}

export const DistanceItem = ({
  field,
  onClick,
  remove,
}: IDistanceItemProps) => {

  

  return (
    <div className={styles.participationCostContainer}>
      <div>
        <h6 className={styles.participationCostTitle}>
          {/*@ts-ignore*/}
          {field?.price} <span style={{ fontSize: "14px" }}>сом</span>
        </h6>
        <div className={styles.participationDateContainer}>
          <img
            className={styles.participationDateCalendar}
            src={calendarSvg}
            alt="Календарь"
          />
          <span className={styles.participationBeforeDate}>
            {dayjs(field?.dateStart).format("DD.MM.YYYY")}
          </span>
          <span className={styles.participationDateDivider}>-</span>
          <span className={styles.participationUntilDate}>
            {/*@ts-ignore*/}
            {dayjs(field?.dateEnd).format("DD.MM.YYYY")}
          </span>
        </div>
      </div>
      {true && (
        <div>
          <Button
            type="button"
            className={styles.participationEditButton}
            onClick={onClick}
          >
            <img
              src={editSvg}
              alt="Кнопка редактирования"
              className={styles.participationEditImg}
            />
          </Button>
          {true && (
            <Button
              type="button"
              className={styles.participationDeleteButton}
              color="error"
              onClick={() => remove()}
            >
              <img
                src={deleteSvg}
                alt="Кнопка удаления"
                className={styles.participationDeleteImg}
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
