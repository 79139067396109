import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const getMarathonCreateThunk = createAsyncThunk(
    "personalCabinet/marathon",
    async (id, { rejectedWithValue }) => {
        try {
            const { data } = await personalCabinetAPI.getMarathon(id)
            return data;
          } catch (error) {
            return rejectedWithValue(error.response.data);
          }
    }
)
