import { ICreateMarathon } from "features/createMarathon/types";
import { useFieldArray, useFormContext } from "react-hook-form";

export const usePeriodDistance = (index: number) => {
  const { control } = useFormContext<ICreateMarathon>();
  const {
    append: appendPeriodDistance,
    update: updatePeriodDistance,
    fields: fieldsPeriodDistance,
    remove: removePeriodDistance,
    insert: insertPeriodDistance
  } = useFieldArray({
    control,
    name: `distances.${index}.distancePrices`,
  });
  return {
    appendPeriodDistance,
    updatePeriodDistance,
    fieldsPeriodDistance,
    removePeriodDistance,
    insertPeriodDistance
  };
};
