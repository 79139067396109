import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "./../api/index";

export const postApplicationCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationVaucher",
  async (data, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.postApplication(data);
      return res.data;
    } catch (error) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title);
    }
  }
);
