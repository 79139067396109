import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const adminGetMarathon = createAsyncThunk(
  "admin/getMarathonByIdThunk",
  async (id: any) => {
    try {
      const { data, } = await adminAPI.getMarathonAdminById(id);
      return data;
    } catch (error) {
      console.log("ERROR =>>>>", error);
    }
  }
);
