import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SignInSkeleton: React.FC = (): JSX.Element => {
  return (
    <Grid item sx={{
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      }
    }}>
      <Skeleton animation="pulse" variant="rectangular" sx={{
        width: '50%',
        minHeight: '91.9vh',
        '@media screen and (max-width: 767px)': {
          flexDirection: 'column',
          minHeight: '35vh',
          width: '100%'
        }
      }}/>
      <Stack
        sx={{
          width: '30%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '@media screen and (max-width: 1130px)': {
            width: '40%',
          },
          '@media screen and (max-width: 767px)': {
            height: '50vh',
            width: '60%',
          },
          '@media screen and (max-width: 560px)': {
            width: '80%',
          }
        }}>
        <Skeleton
          animation="pulse"
          variant="text"
          width='60%'
          sx={{
            fontSize: '28px'
          }}/>
        <Skeleton
          animation="pulse"
          height={50}
          sx={{
            width: '75%',
            borderRadius: '10px',
            '@media screen and (max-width: 767px)': {
              width: '40%',
            }
          }}/>
        <Grid item sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Skeleton animation="pulse" variant="text" sx={{
            fontSize: '14px',
            marginBottom: '10px',
            width: '31%',
            '@media screen and (max-width: 767px)': {
              marginBottom: '2px',
            }
          }}/>
          <Skeleton animation="pulse" variant="text" sx={{
            fontSize: '14px',
            marginBottom: '10px',
            width: '31%',
            '@media screen and (max-width: 767px)': {
              marginBottom: '2px',
            }
          }}/>
          <Skeleton animation="pulse" variant="text" sx={{
            fontSize: '14px',
            marginBottom: '10px',
            width: '31%',
            '@media screen and (max-width: 767px)': {
              marginBottom: '2px',
            }
          }}/>
        </Grid>
        <Skeleton
          animation="pulse"
          variant="text"
          width='10%'
          sx={{
            fontSize: '14px',
            alignSelf: 'flex-start',
            marginBottom: '-10px',
          }}/>
        <Skeleton
          animation="pulse"
          width='100%'
          height={60}
          sx={{
            borderRadius: '15px',
          }}/>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Skeleton
            animation="pulse"
            variant="text"
            width={40}
            sx={{
              fontSize: '14px',
              marginBottom: '-10px',
            }}/>
          <Skeleton
            animation="pulse"
            variant="text"
            width={80}
            sx={{
              marginBottom: '-10px',
              fontSize: '14px',
            }}/>
        </div>
        <Skeleton
          animation="pulse"
          width='100%'
          height={60}
          sx={{
            borderRadius: '15px'
          }}/>
        <Skeleton
          animation="pulse"
          width='100%'
          height={60}
          sx={{
            borderRadius: '15px'
          }}/>
        <Skeleton
          animation="pulse"
          variant="text"
          width='50%'
          sx={{
            fontSize: '18px'
          }}/>
      </Stack>
    </Grid>
  );
};

export default SignInSkeleton;
