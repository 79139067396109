import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";

export const deleteStatusComment = createAsyncThunk(
  "admin/deleteStatusComment",
  async (id : any) => {
    try {
      const { data } = await adminAPI.deleteStatusComment(id)
      return data
    } catch (error) {
      console.log(error);
    }
  }
);
