import { ICreateMarathon } from "features/createMarathon/types";
import { Control, useFieldArray } from "react-hook-form";

interface UseParthnerCompanyProps {
  control: Control<ICreateMarathon, any>;
  index: number;
}

export const useParthnerCompany = ({
  control,
  index,
}: UseParthnerCompanyProps) => {
  const { append, fields, remove } = useFieldArray({
    name: `partners.${index}.partnerCompanies`,
    control,
  });

  return {
    append,
    fields,
    remove,
  };
};
