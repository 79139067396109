import {createAsyncThunk} from "@reduxjs/toolkit";
import {typedAdminAPI} from '../api/typedApi';

export const getParticipantInfoBySearch = createAsyncThunk(
  'getParticipantInfoBySearch/admin' , async (participantInfo: {voucherId: number, search: string}, {rejectWithValue}) => {
    try {
      const response = await typedAdminAPI.getParticipantInfoBySearch(participantInfo);
      return response.data;
    } catch (error) {
      //Don't need to handle error
      return rejectWithValue('Error');
    }
  }
)
